@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Statistics {
	width: 40.5em;
	@include box-shadow(0em, 0.65em, 0.2em, 0.05em, rgba(#0B2A53, 0.4));
	border-radius: 0.5em;

	.Statistics-wrapper {
		width: 100%;
		background-color: $white;
		border-radius: 0.5em;
		box-shadow: 0em 0.45em $boxBottomGray;
		padding: 1em;

		.Statistics-statPopup {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			@include flex('center', 'center');

			.Statistics-statWindow {
				background-color: $white;
				@include box-shadow(0em, 0.3em, 0.3em, 0.05em, rgba(#0B2A53, 0.4));
				padding: 0.5em;
				border-radius: 0.5em;

				.Statistics-statWrapper {
					@include flex('space-between');

					.Statistics-statName {
						padding-bottom: 0.25em;
						padding-left: 1em;
						padding-right: 1.5em;
						width: 10em;
						span {
							color: $text-dark;
							font-weight: bold;
						}	
					}
						
					&:first-child {
						.Statistics-statValue {
							border-top-left-radius: 0.5em;
							border-top-right-radius: 0.5em;
						}
					}

					&:last-child {
						.Statistics-statValue {
							border-bottom-left-radius: 0.5em;
							border-bottom-right-radius: 0.5em;
						}
					}

					.Statistics-statValue {
						width: 12em;
						padding-left: 0.5em;
						padding-right: 0.5em;
						background-color: $lightGrayBlue;
						margin-bottom: 0.1em;

						span {
							color: $text-dark;
							font-size: 0.9em;
							font-weight: 500;
						}
					}
				}
			}
		}

		.Statistics-form {
			@include flex('space-between', 'center');
			background-color: $uiGradientLightBlue;
			color: $text-light;
			border-radius: 0.5em;
			padding-left: 0.5em;
			padding-right: 0.5em;
			padding: 0.5em;

			margin-bottom: 0.5em;

			.Statistics-searchTitle {
				padding-bottom: 0.25em;
				span {
					text-transform: capitalize;
					font-weight: bold;
				}
			}
			
			.Statistics-symbol {
				@include flex('center', 'center');
				span {
					font-weight: 900;
					font-size: 2em;
					line-height: 0;
				}
			}

			.Statistics-searchInput {
				span {
					text-transform: capitalize;
				}

				input {
					height: 1.8em;
					padding-left: 0.15em;
					padding-right: 0.15em;
					border-radius: 0.4em;
					margin-left: 0.5em;
					background-color: $lightGrayBlue;
				}
			}
		}

		.Statistics-gameAmount {
			@include flex('space-between', 'center');
			background-color: $lightGrayBlue;
			color: $text-dark;
			height: 2.5em;
			border-radius: 0.5em;
			padding: 0.25em;
			padding-left: 0.5em;
			padding-right: 0.5em;

			span {
				font-weight: bold;
			}
			.Stastitics-gameAmountNumber {
				span {
					font-weight: bold;
				}
			}
		}

		.Statistics-gameList {
			@include flex('', '', 'column');
			overflow-y: hidden;
			color: $text-dark;
			border-radius: 0.5em;
			margin-top: 0.5em;

			.Statistics-gameStatHeader {
				@include flex('space-between');
				background-color: $uiGradientLightBlue;
				color: $text-light;
				padding-bottom: 0.5em;
				border-bottom-style: solid;
				margin-bottom: 0.1em;
				padding: 0.5em;

				.Statistics-gameStatHeaderTitle {
					span {
						font-weight: bold;
					}
				}
			}

			.Statistics-gameStatsWrapper {
				overflow-y: scroll;
				@include hide-scrollbar();
				height: 9.5em;
				
				.Statistics-game {
					@include flex('space-between');
					background-color: $lightGrayBlue;
					margin-bottom: 0.1em;
					padding: 0.5em;

					&.current {
						background-color: $uiGradientLightBlue;
						color: $text-light;
					}

					.Statistics-gameName {
						width: 33%;
						span {
							font-weight: bold;
							font-size: 0.9em;
						}
					}
					.Statistics-icon {
						background-image: url(../../assets/images/icons/icon-magnefyingglass.svg);
						width: 1.4em;
						height: 1.4em;
						background-size: contain;
						cursor: pointer;

						&:hover {
							background-size: 110% 110%;
						}
					}
					.Statistics-gameGroupAmount {
						text-align: end;
						width: 33%;
						span {
							font-size: 0.9em;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
}