@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.TaskIntro {
	position: relative;
	width: 100%;
	height: 100%;
	margin-bottom: 1em;
	.TaskIntro-text {
		color: $text-dark;
		p {
			margin-top: 0;
			font-size: 0.85em;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		span {
			display: inline-block;
			margin-top: 1em;
			font-weight: 900;
			font-size: 0.85em;
		}
	}

	.TaskIntro-image {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	&.area-rectangle-1
	{
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/area-rectangle-1.svg');
			background-size: contain;
			background-position: top -3em right calc(50%);
			height: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}

	&.area-rectangle-2
	{
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/area-rectangle-2.svg');
			background-size: contain;
			background-position: top -3em right calc(50%);
			height: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}

	&.assignment12Angle {
		padding-bottom: 8em;
		margin-bottom: 8em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/assignment-12-angle.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}

	&.calculate-circumference-55 {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/calculate-circumference-55.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}
	&.triangleAngle {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/triangle-angle.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 50%;
		}
		.TaskIntro-text {
			padding-bottom: 1.5em;
		}
	}

	&.beregning
	{
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/round-1-multiple-beregning.svg');
			background-size: contain;
			background-position: top 0 right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}

	&.fnugrulle {
		padding-bottom: 10em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/fnugrulle.svg');
			background-size: contain;
			background-position: top 0 right calc(50%);
			height: 100%;
			width: 20em;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.trappe {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/trappe.svg');
			background-size: contain;
			background-position: top 0 right calc(50%);
			height: 100%;
			width: 20em;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.efterisolering {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/efterisolering.svg');
			background-size: contain;
			background-position: top 0 right calc(50%);
			height: 100%;
			width: 20em;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.hegn {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/hegn.svg');
			background-size: contain;
			background-position: top 0 right calc(50%);
			height: 100%;
			width: 20em;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.vinduesudskiftning {
		padding-bottom: 11em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/vinduesudskiftning.svg');
			background-size: contain;
			background-position: top 0 right calc(50%);
			height: 100%;
			width: 20em;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.butiksindretning {
		padding-bottom: 11em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/butiksindretning.svg');
			background-size: contain;
			background-position: top 1em right calc(50% + 2em);
			height: 100%;
			width: 20em;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.gulv {
		padding-bottom: 10em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/gulv.svg');
			background-size: contain;
			background-position: top 0 right calc(50%);
			height: 100%;
			width: 20em;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.hexagon {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/hexagon.svg');
			background-size: contain;
			background-position: top 0 right calc(50%);
			height: 100%;
			width: 20em;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}

	&.badebro {
		padding-bottom: 17em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/badebro.svg');
			background-size: contain;
			background-position: top -10em right calc(50%);
			width: 45em;
			height: 27em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.badebro2 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/badebro.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.badebro3 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/badebro.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 35em;
			height: 35em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}

	&.tilbygning {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/tilbygning.svg');
			background-size: contain;
			background-position: top -9em right calc(50%);
			width: 45em;
			height: 27em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.tilbygning2 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/tilbygning.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.tilbygning3 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/tilbygning.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 35em;
			height: 35em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}

	&.skaterRampe {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/skateboardrampe.svg');
			background-size: contain;
			background-position: top -9em right calc(50%);
			width: 45em;
			height: 27em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.skaterRampe2 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/skateboardrampe.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.skaterRampe3 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/skateboardrampe.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 35em;
			height: 35em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}

	&.binding {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/bindingsvaerk.svg');
			background-size: contain;
			background-position: top -10em right calc(50%);
			width: 45em;
			height: 27em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.binding2 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/bindingsvaerk.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.binding3 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/bindingsvaerk.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 35em;
			height: 35em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}

	&.typehus {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/typehus.svg');
			background-size: contain;
			background-position: top -9em right calc(50%);
			width: 45em;
			height: 27em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.typehus2 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/typehus.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.typehus3 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/typehus.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 35em;
			height: 35em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}

	&.terrasse {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/traeterrasse.svg');
			background-size: contain;
			background-position: top -8em right calc(50%);
			width: 45em;
			height: 27em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.terrasse2 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/traeterrasse.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.terrasse3 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/traeterrasse.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 35em;
			height: 35em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}

	&.gips {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/gipsvaegge.svg');
			background-size: contain;
			background-position: top -10em right calc(50%);
			width: 45em;
			height: 27em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.gips2 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/gipsvaegge.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.gips3 {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/gipsvaegge.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 35em;
			height: 35em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}

	&.screws {
		padding-bottom: 18em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/screws.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.tag {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/tag.svg');
			background-size: contain;
			background-position: top 1em right calc(50%);
			height: 100%;
			width: 15em;
			height: 15em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.bro {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/bro.svg');
			background-size: contain;
			background-position: top 0 right calc(50%);
			height: 100%;
			width: 15em;
			height: 15em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.hegn-stolpe {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/hegn-stolpe.svg');
			background-size: contain;
			background-position: top -5em right calc(50% + 12em);
			height: 100%;
			width: 40em;
			height: 40em;
		}
		.TaskIntro-text {
			padding-bottom: 0.75em;
		}
	}
	&.geometry {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/geometry.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.polygon-circumference {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/polygon-circumference.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.circle-circumference {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/circle-circumference.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.square-circumference {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/square-circumference.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.halfcircle-circumference {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/halfcircle-circumference.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.quartercircle-circumference {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/quartercircle-circumference.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.triangle-circumference {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/triangle-circumference.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.reactangle-area {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/rectangle-area.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.circle-area {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/circle-area.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.triangle-area {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/triangle-area.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.triangle-area2 {
		padding-bottom: 1em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/triangle-area2.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.squares {
		padding-bottom: 17em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/squares.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 20em;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.koordinatsystem {
		padding-bottom: 15em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/koordinatsystem.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.cylinder {
		padding-bottom: 15em;
		.TaskIntro-image {
			background-image: url('../../../../assets/images/assignments/geometry/cylinder.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 25em;
			height: 25em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}

	&.pricelist-1 {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/pricelist/pricelist-1.svg');
			background-size: contain;
			background-position: top 0em right 0;
			height: 100%;
			width: 100%;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -15.5em;
		}
	}
	&.pricelist-2 {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/pricelist/pricelist-2.svg');
			background-size: contain;
			background-position: top 0em right 0;
			height: 100%;
			width: 100%;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -13em;
		}
	}
	&.pricelist-3 {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/pricelist/pricelist-3.svg');
			background-size: contain;
			background-position: top 0em right 0;
			height: 100%;
			width: 100%;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -15.5em;
		}
	}
	&.pricelist-4 {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/pricelist/pricelist-4.svg');
			background-size: contain;
			background-position: top 0em right 0;
			height: 100%;
			width: 100%;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -9.5em;
		}
	}
	&.pricelist-5 {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/pricelist/pricelist-5.svg');
			background-size: contain;
			background-position: top 0em right 0;
			height: 100%;
			width: 100%;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -14em;
		}
	}
	&.pricelist-6 {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/pricelist/pricelist-6.svg');
			background-size: contain;
			background-position: top 0em right 0;
			height: 100%;
			width: 100%;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -14.5em;
		}
	}

	&.pricelist-1b {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: -1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/pricelist/pricelist-1.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 100%;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -3em;
		}
	}
	&.pricelist-2b {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: -1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/pricelist/pricelist-2.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 100%;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -2em;
		}
	}
	&.pricelist-3b {
		position: relative;
		padding-bottom: 1em;
		margin-bottom: -1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/pricelist/pricelist-3.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 100%;
			height: 20em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -2em;
		}
	}
	&.pricelist-4b {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/pricelist/pricelist-4.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 100%;
			height: 18em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -2em;
		}
	}
	&.pricelist-5b {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/pricelist/pricelist-5.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 100%;
			height: 18em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -2em;
		}
	}
	&.pricelist-6b {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/pricelist/pricelist-6.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 100%;
			height: 18em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -2em;
		}
	}

	&.cushion-box {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/geometry/cushion-box.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 100%;
			height: 20em;
			margin-bottom: -5em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
		}
	}

	&.cushion-box2 {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/cushion-box2.svg');
			background-size: 35em 17.75em;
			background-position: top 0em right calc(50% - 3.5em);
			height: 100%;
			width: 100%;
			height: 12em;
			width: 35em;
			@include flip(3.1, 1);
			margin-bottom: -2em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -1em;
		}
	}

	&.cushion-box3 {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/geometry/cushion-box.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 100%;
			height: 16em;
			margin-bottom: -2em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
		}
	}

	&.lidless-box {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/geometry/lidless-box.svg');
			background-size: contain;
			background-position: top 0em right calc(50%);
			height: 100%;
			width: 100%;
			height: 38em;
			margin-bottom: -18em;
		}
		.TaskIntro-text {
			padding-bottom: 0em;
			margin-bottom: -8em;
		}
	}

	&.floor-price {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/pricelist/floor-price.svg');
			background-size: 25em 14em;
			background-position: top 0em right calc(50% - 3.5em);
			height: 100%;
			width: 100%;
			height: 14em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}

	&.energysticker {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/energysticker.svg');
			background-size: 14em 14em;
			background-position: top 0em left 0em;
			height: 100%;
			width: 100%;
			height: 15em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}

	&.couples-bench {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/furniture/couples-bench.png');
			background-size: 14em 14em;
			background-position: top 0em left 0em;
			height: 100%;
			width: 100%;
			height: 15em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.garden-bench {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/furniture/garden-bench.png');
			background-size: 14em 14em;
			background-position: top 0em left 0em;
			height: 100%;
			width: 100%;
			height: 15em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.leopold-chair {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/furniture/leopold-chair.png');
			background-size: 14em 14em;
			background-position: top 0em left 0em;
			height: 100%;
			width: 100%;
			height: 15em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.new-hospital {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/furniture/new-hospital.png');
			background-size: 14em 14em;
			background-position: top 0em left 0em;
			height: 100%;
			width: 100%;
			height: 15em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.stair-furniture {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/furniture/stair-furniture.png');
			background-size: 14em 14em;
			background-position: top 0em left 0em;
			height: 100%;
			width: 100%;
			height: 15em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.table-bench-covered {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/furniture/table-bench-covered.png');
			background-size: 14em 14em;
			background-position: top 0em left 0em;
			height: 100%;
			width: 100%;
			height: 15em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.table-bench-set {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/furniture/table-bench-set.png');
			background-size: 14em 14em;
			background-position: top 0em left 0em;
			height: 100%;
			width: 100%;
			height: 15em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.woodHardness {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/materials/wood-hardness.svg');
			background-size: auto 25em;
			background-position: top 0em left 0em;
			height: 100%;
			width: 100%;
			height: 25em;
			margin-bottom: -26em;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.triangleLetters {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/geometry/triangle-letters.svg');
			background-size: 25em auto;
			background-position: top 0em left 0em;
			height: 100%;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.polygonCircumference {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/geometry/polygon-circumference2.svg');
			background-size: 25em auto;
			background-position: top 0em left 0em;
			height: 100%;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.boxGeometry {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/geometry/box-geometry.svg');
			background-size: 40em auto;
			background-position: top -6em left 0em;
			height: 100%;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.cylinderGeometry {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/geometry/cylinder-geometry.svg');
			background-size: 50em auto;
			background-position: top -9em left -10em;
			height: 100%;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 1em;
		}
	}
	&.statistics {
		position: relative;
		padding-bottom: 1em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/statistics.svg');
			background-size: auto 14em;
			background-position: top 0em left 0em;
			height: 14em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}
	&.educationMap {
		position: relative;
		padding-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/education-map.svg');
			background-size: auto 15em;
			background-position: top 0em left 0em;
			height: 15em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}
	&.woodLongevity {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/materials/wood-longevity.svg');
			background-size: auto 20em;
			background-position: top 0em left 0em;
			height: 10.5em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.girls-diagram {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/set-a-1/girls-diagram.svg');
			background-size: 20em auto;
			background-position: top 0em left 0em;
			height: 16em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.figure-area {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/set-a-1/figure-area.svg');
			background-size: 20em auto;
			background-position: top 0em left 0em;
			height: 18em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}
	&.figure-area-2 {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/set-a-1/figure-area-2.svg');
			background-size: 20em auto;
			background-position: top 0em left 0em;
			height: 18em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}
	&.figure-area-3 {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/set-a-1/figure-area-3.svg');
			background-size: 20em auto;
			background-position: top 0em left 0em;
			height: 18em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}
	&.figure-triangle {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/set-a-1/figure-triangle.svg');
			background-size: 20em auto;
			background-position: top 0em left 0em;
			height: 20em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}
	&.figure-triangle-2 {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/set-a-1/figure-triangle-2.svg');
			background-size: 20em auto;
			background-position: top 0em left 0em;
			height: 20em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}
	&.figure-triangle-3 {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/set-a-1/figure-triangle-3.svg');
			background-size: 20em auto;
			background-position: top 0em left 0em;
			height: 20em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}
	&.figure-triangle-4 {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/set-a-1/figure-triangle-4.svg');
			background-size: 20em auto;
			background-position: top 0em left 0em;
			height: 20em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.wooden-construction {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/set-a-1/wooden-construction.svg');
			background-size: auto 10em;
			background-position: top 0em left 0em;
			height: 10em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.concrete-construction {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/set-a-1/concrete-construction.svg');
			background-size: auto 10em;
			background-position: top 0em left 0em;
			height: 10em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.price-diagram {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/price-diagram.svg');
			background-size: auto 15em;
			background-position: top 0em left 0em;
			height: 15em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.price-diagram-2 {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/price-diagram-2.svg');
			background-size: auto 15em;
			background-position: top 0em left 0em;
			height: 15em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.countersunk-screws {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/countersunk-screws.svg');
			background-size: auto 15em;
			background-position: top 0em left 0em;
			height: 15em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.educations {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/educations.svg');
			background-size: auto 17em;
			background-position: top 0em left 0em;
			height: 17em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.educations-girls {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/educations-girls.svg');
			background-size: auto 20em;
			background-position: top 0em left 0em;
			height: 20em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.educations-highschools-applicants {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/educations-highschools-applicants.svg');
			background-size: auto 20em;
			background-position: top 0em left 0em;
			height: 20em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}

	&.shopping-list {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/shopping-list.svg');
			background-size: auto 18em;
			background-position: top 0em left 0em;
			height: 20em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}
	&.shopping-list-2 {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/shopping-list-2.svg');
			background-size: auto 18em;
			background-position: top 0em left 0em;
			height: 20em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}
	&.shopping-list-3 {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/shopping-list-3.svg');
			background-size: auto 18em;
			background-position: top 0em left 0em;
			height: 20em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}
	&.assignment-list {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/assignment-list.svg');
			background-size: auto 18em;
			background-position: top 0em left 0em;
			height: 20em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}
	&.price-sum {
		position: relative;
		padding-bottom: 0em;
		margin-bottom: 0em;
		.TaskIntro-image {
			position: relative;
			background-image: url('../../../../assets/images/assignments/price-sum.svg');
			background-size: auto 18em;
			background-position: top 0em left 0em;
			height: 20em;
			width: 100%;
		}
		.TaskIntro-text {
			padding-bottom: 0.5em;
		}
	}
}
