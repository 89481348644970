@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GuidePopup {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 99;

	.GuidePopup-window {
		height: 100%;
		padding: 1.5em;
		@include flex('center', 'center');

		.GuidePopup-closeButton {
			position: absolute;
			background-image: url(../../../assets/images/icons/icon-close.svg);
			background-size: contain;
			top: 2.5em;
			right: calc(50% - 29em);
			width: 2em;
			height: 2em;
			cursor: pointer;
			@include transition(0.1s, 'transform');

			&:hover {
				@include scale(1.1);
			}
		}

		.GuidePopup-content {
			position: relative;
			height: 100%;
			width: 64.3em;
			overflow-y: hidden;
			overflow: auto;
			padding: 3em;
			padding-left: calc(6.44em - 1.6em);
			padding-right: calc(8.8em - 1.6em);
			border-color: $white;
			border-style: solid;
			border-width: 1.3em;
			background-color: rgba($white, 0.99);
			border-radius: 0.56em;
			@include box-shadow(0em, 0em, 0.2em, 0.2em, rgba(#0B2A53, 0.4));
			@include custom-scrollbar(0.7em, transparent, $lightGrayBlue);

			.GuidePopup-header {
				width: 100%;
				border-bottom-style: solid;
				border-color: $boxBottomGray;
				border-width: 0.2em;
				padding-bottom: 1.3em;
				margin-bottom: 1.8em;

				span {
					font-size: 1.8em;
					font-weight: 900;
					color: $text-dark;
				}
			}

			.GuidePopup-sectionDivision {
				@include flex('');
				
				.GuidePopup-sectionDivisionLeft {
					width: 32.7em;
				}
			}

			.GuidePopup-materialInterface {
				position: absolute;
				width: 20.6em;
				height: 16.9em;
				margin-left: 1.7em;
				margin-top: 2.1em;
				right: 2em;
			}

			.GuidePopup-video {
				width: 33.4em;
				height: auto;
			}

			.GuidePopup-textHeader {
				p {
					font-weight: 900;
				}
			}
			.GuidePopup-textHeader,
			.GuidePopup-text {
				line-height: 1.7em;
				p {
					font-size: 1.17em;
					color: $text-dark;
				}
			}

			.GuidePopup-line {
				width: 100%;
				height: 0.5em;
				
				border-bottom-style: solid;
				border-color: $boxBottomGray;
				border-width: 0.2em;

				margin-bottom: 2.5em;
			}

			.GuidePopup-logo {
				position: relative;
				margin-top: 1.8em;
				@include flex('space-between');
				
				.GuidePopup-logoUnord {
					background-size: contain;
					background-image: url(../../../assets/images/logos/unord_logo.svg);
					width: 10.3em;
					height: 1.7em;
				}
				.GuidePopup-logoGamelab {
					background-size: contain;
					background-image: url(../../../assets/images/logos/cphgamelab_Logo.svg);
					width: 11.3em;
					height: 2.2em;
				}
				.GuidePopup-logoFogs {
					background-size: contain;
					background-image: url(../../../assets/images/logos/fogs-fond-logo.svg);
					width: 10.5em;
					height: 2.7em;
				}
			}
		}
	}
}