@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.MaterialsWindow {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 2em;
	padding-top: 1.4em;
	background-color: $lightGrayBlue;
	border-radius: 0.5em;
	@include flex('', 'center', 'column');

	.MaterialsWindow-close {
		position: absolute;
		right: 0.7em;
		top: 0.6em;
		width: 1.9em;
		height: 1.9em;
		cursor: pointer;
		background-image: url(../../../assets/images/icons/icon-close.svg);
		background-size: contain;
	}
	.MaterialsWindow-title {
		margin-bottom: 1em;
		span {
			font-weight: 900;
			font-size: 1.3em;
		}
	}
	.MaterialsWindow-dialogueWrapper {
		width: 100%;
		margin-bottom: 0.8em;
		.MaterialsWindow-button {
			width: 100%;
			margin-bottom: 0.8em;
		}
	}
}
