@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.OrderDndItem {
	position: relative;
	width: 100%;
	min-height: 2.25em;
	line-height: 1.25em;
	border-radius: 0.55em;
	font-weight: bold;
	text-align: center;
	padding: 0.75em 2em 0.5em 2em;
	cursor: pointer;
	@include flex('center', 'center');
	@include no-select();
	@include pushable-button();
	span {
		color: white;
		font-size: 0.85em;
		font-weight: bold;
	}

	&.correct {
		@include animateCorrect();
		@include correctAnswer-button();
	}

	.OrderDndItem-handle {
		position: absolute;
		height: 100%;
		width: 2em;
		right: 0;
		top: 0;
		background-image: url('../../../../assets/images/icons/icon-arrow-cross.svg');
		background-position: top 0.5em left 0.25em;
		background-size: auto 1.25em;
		cursor: move;
		&.disabled {
			cursor: not-allowed;
			background-image: none;
		}
	}
}