@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Assignments {
	position: relative;
	z-index: 3;
	height: 100%;
	width: 100%;
	padding-top: 1em;

	border-radius: 0.5em;

	border-top: solid;
	border-top-color: $uiLightBlue;
	border-top-width: 0.25em;

	@include box-shadow(0em, 0.4em, 0, 0, $uiDarkBlue);

	background-image: linear-gradient(
		to bottom,
		$uiGradientLightBlue 10%,
		$uiGradientDarkBlue 40%
	);

	@include flex('', '', 'row');

	.Assignments-overviewWrapper
	{
		width: 35%;
		height: 100%;

		.Assignments-overviewInfo {
			height: 4em;
		}

		.Assignments-overviewTextWrapper
		{
			@include flex('', 'flex-end', 'row');
			color: $text-light;
			padding-left: 2em;
			
			span {
				font-size: 0.8em;		
			}
			
			.Assignments-overviewText
			{
				@include flex('', 'flex-end', 'row');
				font-weight: bold;
				text-transform: uppercase;
				padding-left: 0.5em;

				.Assignments-currency
				{
					text-transform: lowercase;
					padding-left: 0.2em;
					span {
						font-size: 0.8em;
					}
				}
			}
		}

		.Assignments-lists
		{
			position: relative;
			width: 25em;
			height: calc(100% - 4em);
			padding-left: 2em;
			padding-top: 1em;

			.Assignments-difficultyWrapper
			{
				@include flex('space-between','','row');
				width: 100%;
				height: 2.5em;

				border-bottom: solid;
				border-bottom-color: $white;
				border-bottom-width: 0.1em;

				.Assignments-listDifficulty
				{
					@include flex('center','center');
					cursor: pointer;
					width: 100%;
					padding: 0.5em;
					border-top-left-radius: 0.5em;
					border-top-right-radius: 0.5em;

					background-color: $overlayBlue;
					color: $text-light;

					span
					{
						font-weight: bold;
						text-transform: uppercase;
					}

					&.isActive
					{
						background-color: $white;
						color: $text-dark;
					}

					&.notLast
					{
						margin-right: 0.2em;
					}
				}
			}

			.Assignments-shownTasks
			{
				@include hide-scrollbar();
				overflow-y: auto;
				width: 100%;
				height: calc(100% - 3.5em);

				.Assignments-task
				{
					@include flex('space-between','','row');
					width: 100%;
					cursor: pointer;
					border-radius: 0.5em;
					padding: 0.5em;
					padding-right: 0.75em;
					padding-left: 1.5em;
					margin-top: 1em;

					background-color: $lightBlue;

					&.active {
						background-color: $white;
					}

					&.done:not(.active) {
						opacity: 0.6;
					}

					.Assignments-taskTitle
					{
						span
						{
							text-transform: uppercase;
							font-weight: bold;
							font-size: 0.9em;
						}
					}

					.Assignment-taskData
					{
						@include flex('','center','row');

						.Assignment-value 
						{
							display: none;
							margin-right: 0.5em;

							span {
								text-transform: uppercase;
								font-weight: bold;
								font-size: 0.9em;
							}

							&.active 
							{
								display: block;
							}
						}
						.Assignment-doneIndicator 
						{
							width: 1.5em;
							height: 1.5em;
							border-radius: 0.25em;
							background-color: $uiGradientDarkBlue;
							opacity: 0.4;

							&.checkMarked
							{
								opacity: 1;
								padding: 0.25em;
								background-image: url(../../../../assets/images/icons/icon-checkmark.svg);
								background-size: contain;
								background-origin: content-box;
							}
						}
					}
				}
			}
		}
	}

	.Assignments-currentAssignment
	{
		border-bottom-right-radius: 0.5em;
		width: 65%;
		height: 100%;
		background-color: $white;

		@include box-shadow(0em, 0.4em, 0, 0, $boxBottomGray);

		.Assignments-assignmentWrapper
		{
			@include flex('','','column');
			height: 100%;
			padding: 1em;
			.Assignments-assignmentHeader
			{
				@include flex('space-between','','row');
				border-bottom: solid;
				border-bottom-color: $boxBottomGray;
				border-bottom-width: 0.15em;
				padding-bottom: 1em;

				.Assignments-assignmentTitle
				{
					padding-top: 0.5em;

					span
					{
						font-size: 1.1em;
						font-weight: bold;
					}
				}
				.Assignments-assignmentValue
				{
					background-color: $lightGrayBlue;
					border-radius: 0.5em;
					padding: 0.5em;
					padding-left: 3.5em;
					padding-right: 3.5em;

					span
					{
						font-size: 1.1em;
						font-weight: bold;
						text-transform: uppercase;
					}
				}
			}
		}
	}
}