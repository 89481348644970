@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.BidBox {
	position: relative;
	z-index: 2;
	box-shadow: 0em 0.3em 0.5em 0.3em $boxShadow;
	border-radius: 0.57em;
	
	.BidBox-inner
	{
		@include groupBackgroundColor();

		position: relative;
		border-radius: 0.57em;
		
		&.hasBidWinner {
			&.groupBorderColor1 {
				box-shadow: 0em 0em 0em 0.25em $group1;
			}
			&.groupBorderColor2 {
				box-shadow: 0em 0em 0em 0.25em $group2;
			}
			&.groupBorderColor3 {
				box-shadow: 0em 0em 0em 0.25em $group3;
			}
			&.groupBorderColor4 {
				box-shadow: 0em 0em 0em 0.25em $group4;
			}
			&.groupBorderColor5 {
				box-shadow: 0em 0em 0em 0.25em $group5;
			}
			&.groupBorderColor6 {
				box-shadow: 0em 0em 0em 0.25em $group6;
			}
		}

		&.hasBid
		{
			box-shadow: 0em 0em 0em 0.25em $darkBlue;
		}

		.BidBox-interface
		{
			position: relative;
			z-index: 3;
			background-color: $white;
			width: 12.5em;
			padding: 0.5em;
			padding-top: 0.6em;
			padding-bottom: 0.4em;
			border-radius: 0.5em;
			border-top-right-radius: 1em;

			.BidBox-bidIndicators {
				@include flex('','','row');

				.BidBox-bidIndicator
				{
					@include flex('center', 'center');
					box-shadow: inset 0.1em 0.1em 0.2em $boxShadow;
					height: 1.25em;
					width: 1.25em;
					margin-right: 0.2em;
					margin-bottom: 0.5em;

					border-radius: 0.25em;

					span
					{
						color: $text-light;
						font-size: 0.8em;
					}

					&.noBid
					{
						box-shadow: none;
						background-color: $lightGrayBlue;
					}
				}
			}

			.BidBox-bidIndicator
			{
				@include flex('center', 'center');
				box-shadow: inset 0.1em 0.1em 0.2em $boxShadow;
				height: 1.25em;
				width: 1.25em;
				margin-bottom: 0.5em;

				border-radius: 0.25em;

				span
				{
					color: $text-light;
					font-size: 0.8em;
				}

				&.noBid
				{
					box-shadow: none;
					background-color: $lightGrayBlue;
				}
			}

			.BidBox-cornerWrapper
			{
				top: 0em;
				right: 0em;
				position: absolute;
				clip-path: polygon(100% 100%, 100% 0, 0 0);

				.BidBox-corner
				{
					position: relative;
					box-shadow: inset 0.2em 0.2em 0.3em $boxShadow;
					height: 2.25em;
					width: 2.25em;
					border-top-right-radius: 0.5em;

					&.checkMarked {
						background-image: url(../../../assets/images/icons/icon-checkmark.svg);
						background-size: 0.9em 0.9em;
						background-position: top 0.25em right 0.25em;
					}

					&.noBid
					{
						background-color: $gray;
					}
				}
			}
			.BidBox-bidInfoWrapper
			{
				width: 100%;

				background-color: $lightGray;

				border-top-left-radius: 0.5em;
				border-top-right-radius: 0.5em;

				padding: 0.5em;
				color: $text-dark;

				.BidBox-bidInfo
				{
					text-transform: uppercase;
					font-weight: 500;
					font-size: 0.8em;

					&.underlined
					{
						border-bottom: 0.15em solid $gray;
					}
				}
			}
			.BidBox-bidValueWrapper
			{
				width: 100%;
				
				background-color: $lightGrayBlue;
				
				border-bottom-left-radius: 0.5em;
				border-bottom-right-radius: 0.5em;

				padding: 0.5em;
				color: $text-dark;

				.BidBox-bidValue
				{
					font-weight: 900;
					font-size: 0.9em;
				}
			}

			.BidBox-bidWrapper
			{
				padding-top: 0.5em;
				@include flex('space-between', 'center', 'row');

				.BidBox-bidPreferences
				{
					@include flex('', 'center', 'row');
					.BidBox-bidPreference
					{
						height: 2.2em;
						width: 2.2em;
						background-size: contain;
						background-position: top 0em left 0em;
						margin-right: 0.25em;

						&.secondPriority
						{
							opacity: 0.6;
						}

						@include groupStatIcons();
					}
				}
				.BidBox-bidButtonWrapper
				{
					@include flex('space-between', 'center', 'row');

					color: $boxBottomGray;
					border: solid;
					border-width: 0.15em;
					border-radius: 0.5em;
					padding: 0.2em;
					padding-right: 0em;

					.BidBox-bidText
					{
						color: $uiDarkBlue;
						margin-right: 0.25em;
						span
						{
							text-transform: uppercase;
							font-size: 0.8em;
							font-weight: bold;
						}
					}

					.BidBox-bidButton
					{
						@include flex('center', 'center');
						width: 1.7em;
						height: 1.7em;
						border-radius: 0.5em;
						opacity: 0.5;
						margin-right: 0.2em;

						background-color: $uiGradientLightBlue;
						pointer-events: none;
						
						.BidBox-bidButtonIconMinus
						{
							width: 1em;
							height: 1em;
							background-size: contain;
							background-image: url(../../../assets/images/icons/icon-minus.svg);
						}

						.BidBox-bidButtonIconPlus
						{
							width: 1em;
							height: 1em;
							background-size: contain;
							background-image: url(../../../assets/images/icons/icon-plus.svg);
						}

						&.active
						{
							pointer-events: initial;
							cursor: pointer;
							opacity: 1;
						}
					}
					.BidBox-bidButton:hover 
					{
						background-color: $lightBlueHighlight;
					}
				}
			}
		}
		
		.BidBox-grayBottom
		{
			z-index: 2;
			position: relative;
			width: 100%;
			height: 1em;
			margin-top: -0.7em;
			background-color: $boxBottomGray;
			border-radius: 0.5em;
		}
	}
}