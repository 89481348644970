@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.MaterialsPopup {
	@include flex('center', 'center');
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 5;

	background-color: rgba($overlayBlue, 0.5);

	.MaterialsPopup-window {
		position: relative;
		width: 29em;
		background-color: $white;
		border-radius: 0.5em;
		@include box-shadow(0em, 0.4em, 0, 0, $boxBottomGray);
		@include flex('', 'center', 'column');
		padding: 2.7em;
		padding-top: 3.4em;

		.MaterialsPopup-close {
			position: absolute;
			right: 0.7em;
			top: 0.6em;
			width: 1.9em;
			height: 1.9em;
			cursor: pointer;
			background-image: url(../../../assets/images/icons/icon-close.svg);
			background-size: contain;
		}
		.MaterialsPopup-title {
			margin-bottom: 1.6em;
			span {
				text-transform: uppercase;
				font-weight: bold;
				font-size: 1.6em;
			}
		}

		.MaterialsPopup-difficultyDialogue,
		.MaterialsPopup-materialsDialogue {
			.MaterialsPopup-button {
				width: 15em;
				margin-bottom: 0.8em;
			}
		}
	}
}
