@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GroupSidebar
{
	@include flex('', '', 'column');
	z-index: 2;
	position: relative;
	right: 0em;
	top: 1em;

	padding: 1em;

	border-left: solid;
	border-left-color: $uiDarkBlue;
	border-left-width: 0.1em;

	border-top: solid;
	border-top-color: $uiLightBlue;
	border-top-width: 0.2em;

	border-bottom: solid;
	border-bottom-color: $uiDarkBlue;
	border-bottom-width: 0.3em;

	border-top-left-radius: 0.5em;
	border-bottom-left-radius: 0.5em;

	background-image: linear-gradient(
		to bottom,
		$uiGradientLightBlue 10%,
		$uiGradientDarkBlue 40%
	);

	.GroupSidebar-sidebarTitle
	{
		margin-bottom: 0.5em;
		span
		{
			color: $text-light;
			font-weight: 900;
			font-size: 1.2em;
			text-transform: uppercase;
		}
	}

	.GroupSidebar-currentGroup
	{
		margin-bottom: 0.5em;
	}

	.GroupSidebar-groups
	{
		.GroupSidebar-groupInfo
		{
			margin-top: 0.5em;
			
			&.notVoted
			{
				opacity: 0.5;
			}
		}
	}
}