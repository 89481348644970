@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.DeleteGamePopup {
	position: fixed;
	z-index: 5;
	top: 0;
	left: 0;
	width: 100%;
	bottom: 0;
	background: rgba($overlayBlue, 0.5);
	@include flex('center', 'center');

	.DeleteGamePopup-content  {
		position: relative;
		width: 29em;
		color: $text-dark;
		background-color: $white;
		border-radius: 0.67em;
		@include box-shadow(0em, 0.4em, 0, 0, $boxBottomGray);
	
		.DeleteGamePopup-header {
			position: relative;
			padding: 2.25em 0 1em 0;
			background-size: auto 2em;
			background-position: center top 0.75em; 
			background-repeat: no-repeat;
			.DeleteGamePopup-title {
				font-size: 1.5em;
				text-align: center;
				font-weight: bold;
				text-transform: uppercase;
			}
		}	

		.DeleteGamePopup-body {
			padding: 0 1em 1em 1em;
			text-align: center;

			.DeleteGamePopup-text {
				padding: 1em;
				font-weight: 400;
			}
			.DeleteGamePopup-buttons {
				@include flex('space-between');

				width: 100%;
				padding: 2em;

				.Button {
					width: 10em;
				}
			}
		}
	}
}


@media only screen and (max-aspect-ratio: 1/1) {
	.DeleteGamePopup {
		width: 100%;
	}
}