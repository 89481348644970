@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GameCode
{
	@include flex('center', '', 'column');

	position: relative;
	z-index: 3;
	border-radius: 0.3em;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
	width: fit-content;
	
	.GameCode-code
	{
		@include flex('', 'center', 'column');
		padding: 0.25em;
		width: 100%;
		line-height: 1.4em;

		span
		{
			text-transform: uppercase;
			color: $uiGradientDarkBlue;
			font-weight: bold;
			font-size: 1.05em;
		}

		.GameCode-codeText
		{
			@include flex('center', 'center');
			padding-left: 0.75em;
			padding-right: 0.75em;
			height: 2.35em;
			background-color: $uiGradientDarkBlue;
			border-radius: 0.3em;

			span {
				text-transform: lowercase;
				font-size: 1.6em;
				color: $white;
				font-weight: bold;
			}
		}
	}

}