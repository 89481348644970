@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/animations';
.App {
	position: relative;
	height: 100%;
	overflow: auto;
	background-color: $gradient1;
	background-image: linear-gradient(
		to top, 
		$gradient1 50%,
		$gradient2,
	);
}