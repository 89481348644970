@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Result
{
	height: 100%;
	@include flex('flex-start', 'center', 'column');
	@include groupBackgroundColor();
	position: relative;
	z-index: 2;

	.Result-headerWrapper
	{
		@include flex('center', 'flex-start', 'row');
		width: 100%;

		.Result-gameCode
		{
			margin: 1em;
			position: absolute;
			left: 0;
			top: 0;
		}
		.Result-progressbar
		{
			margin: 1em;
			width: 60em;
			position: relative;
		}
	}

	.Result-scoreboardWrapper
	{
		@include flex('center', 'center');
		width: 100%;
		margin-top: 1em;
		.Result-scoreboard 
		{
			background-color: $white;
			padding: 1em;
			border-radius: 0.5em;
		
			.Result-scoreboardHeader
			{
				@include flex('', 'center');

				.Result-scoreboardHeaderTitle
				{
					@include flex('center');
					span
					{
						font-size: 0.9em;
						font-weight: bold;
					}

					&.company {
						@include flex('flex-start');
						margin-left: 1.2em;
						width: 13.9em;
					}
					&.earnings {
						width: 15.3em;
						margin-left: 0.125em;
						margin-right: 0.125em;
					}
					&.assignments {
						width: 7.7em;
						margin-left: 0.125em;
						margin-right: 0.125em;
					}
					&.score {
						width: 18.55em;
						margin-left: 0.25em;
						margin-right: 0.25em;
					}
				}
			}
			.Result-groupScoreWrapper
			{
				@include flex('space-between', 'center', 'row');
				position: relative;
				height: 100%;

				border: solid;
				border-width: 0.2em;
				border-color: $boxBottomGray;
				border-radius: 0.5em;
				
				margin-top: 1em;
				padding: 0.1em;
				padding-left: 0.5em;

				.Result-groupName
				{
					width: 13.9em;
					@include flex('flex-start','center');
					font-weight: bold;
				}
				.Result-earnings {
					width: 15.3em;
					height: 100%;
					@include flex('center','center');

					color: $boxBottomGray;
					border-left-style: solid;
					border-width: 0.15em;
					padding: 0.25em;

					span {
						color: $text-dark;
						font-weight: bold;
					}
				}
				.Result-assignmentsSolved
				{
					width: 7.7em;
					height: 100%;
					@include flex('center','center');

					color: $boxBottomGray;
					border-left-style: solid;
					border-right-style: solid;
					border-width: 0.15em;
					padding: 0.25em;

					span {
						color: $text-dark;
						font-weight: bold;
					}
				}
				.Result-groupStats
				{
					width: 18.55em;
					@include flex('center','center','row');
					.Result-groupCapacity
					{
						@include flex('center', 'center');
						width: 1.75em;
						height: 1.75em;
						border-radius: 0.25em;
						box-shadow: inset 0.15em 0.15em 0.3em $boxShadow;

						span
						{
							font-weight: bold;
							color: $text-light;
						}
					}
					.Result-groupStatWrapper
					{
						@include flex('','center','row');
						background-color: $lightGray;
						border-radius: 1em;
						margin: 0.25em;
						.Result-groupStat
						{
							height: 1.75em;
							width: 1.75em;
							&.quality
							{
								background-image: url(../../../assets/images/icons/icon-quality.svg);
								background-size: contain;
							}

							&.workenvironment
							{
								background-image: url(../../../assets/images/icons/icon-workenvironment.svg);
								background-size: contain;
							}

							&.sustainability
							{
								background-image: url(../../../assets/images/icons/icon-sustainability.svg);
								background-size: contain;
							}
						}
						.Result-groupStatNumber
						{
							padding-left: 0.5em;
							padding-right: 0.5em;

							span {
								font-weight: bold;
								font-size: 0.9em;
							}
						}
					}
				}

				.Result-cornerWrapper 
				{
					position: absolute;
					// Compensating for gap
					top: -0.15em;
					right: -0.15em;
					clip-path: polygon(100% 100%, 100% 0, 0 0);
					.Result-corner 
					{
						position: relative;
						box-shadow: inset 0.2em 0.2em 0.3em $boxShadow;
						height: 2.25em;
						width: 2.25em;
						border-top-right-radius: 0.5em;
					}
				}
			}
		}
	}
	.Result-scoreboardNextRound {
		position: absolute;
		bottom: 3em;
	}
}