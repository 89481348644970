@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.BuildTimer
{
	@include flex('space-between','center','column');
	position: relative;
	z-index: 2;
	height: 100%;
	padding-bottom: 5em;

	.BuildTimer-headerWrapper
	{
		@include flex('space-between','flex-start','row');
		width: 100%;

		.BuildTimer-gameCode
		{
			width: 20%;
			margin: 1em;
			position: relative;
		}

		.BuildTimer-progressbar {
			position: relative;
			margin: 1em;
			width: 60%;
			height: 100%;

			.BuildTimer-timer {
				@include flex('center', 'center');
				margin-top: 12em;
			}
		}

		.BuildTimer-sidebar
		{
			width: 20%;
			position: relative;
		}
	}
}