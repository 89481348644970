@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Workshop
{
	@include flex('space-between','','column');
	@include groupBackgroundColor();
	position: relative;
	pointer-events: none;
	z-index: 6;
	height: 100%;
	width: 100%;
	
	.Workshop-assignmentWindow
	{
		pointer-events: all;
		position: absolute;
		height: 85%;
		width: 82.7%;
		margin-top: 5em;
		margin-left: 1em;
	}
	
	.Workshop-header 
	{
		@include flex('space-between','','row');
		.Workshop-logo {
			padding: 1em;
		}
		
		.Workshop-uiWrapper
		{
			@include flex('','','row');
			.Workshop-headerButtons
			{
				@include flex('','','row');
				margin-top: 1em;
				margin-right: 1em;

				.Workshop-headerButton
				{
					margin-left: 1em;	
				}
			}

			.Workshop-groupInfoWrapper
			{
				@include flex('','','column');
				.Workshop-groupInfobox
				{
					margin-top: 1em;
					margin-right: 1em;
					@include box-shadow(0em, 0.4em, 0, 0, $boxBottomGray);
					position: relative;
					background-color: $white;
					border-radius: 0.5em;
					padding: 0.75em;

					.Workshop-groupName
					{
						span
						{
							font-weight: bold;
						}
					}

					.Workshop-cornerWrapper
					{
						position: absolute;
						top: 0em;
						right: 0em;
						clip-path: polygon(100% 100%, 100% 0, 0 0);

						.Workshop-corner
						{
							position: relative;
							box-shadow: inset 0.2em 0.2em 0.3em $boxShadow;
							height: 2.25em;
							width: 2.25em;
							border-top-right-radius: 0.5em;
						}
					}

					.Workshop-groupStats
					{
						@include flex('','center','row');
						margin-top: 0.75em;
						.margined {
							margin-left: 0.25em;
						}

						.Workshop-groupCapacity
						{
							@include flex('center', 'center');
							width: 1.75em;
							height: 1.75em;
							border-radius: 0.25em;

							span
							{
								color: $text-light;
									
							}
						}
						.Workshop-qualityWrapper
						{
							@include flex('','center','row');
							background-color: $lightGrayBlue;
							border-radius: 1em;

							.Workshop-qualityIcon
							{
								width: 1.75em;
								height: 1.75em;
								background-size: contain;

								@include groupStatIcons();
							}
							.Workshop-qualityValue
							{
								padding-left: 0.25em;
								padding-right: 0.5em;
							}
						}
					}
					
					.Workshop-cashDisplay
					{
						@include flex('center');
						width: 100%;
						padding: 0.5em;
						background-color: $lightGrayBlue;
						border-radius: 0.5em;
						margin-top: 0.75em;

						span
						{
							font-size: 1.25;
							font-weight: bold;
							text-transform: uppercase;
						}
					}
				}

				.Workshop-shopItemWindow
				{
					@include flex('', 'center', 'column');
					margin-top: 1em;
					margin-right: 1em;
					background-color: $white;
					border-radius: 0.5em;
					padding: 1em;
					@include box-shadow(0em, 0.3em, 0, 0, $boxBottomGray);
					
					.Workshop-currentItem
					{
						@include flex('', 'center', 'column');
						background-color: $lightGrayBlue;
						background: radial-gradient($white, $radialGradient1);

						width: 100%;
						height: 11em;

						border-radius: 0.5em;

						.Workshop-currentItemImage
						{
							width: 15em;
							height: 100%;
							padding: 1em;
							background-size: contain;
							background-origin: content-box;
							background-position-y: bottom;
							@include shopitems();
						}

						.Workshop-currentItemName
						{
							padding-bottom: 0.75em;
							span
							{
								@include no-select();
							}
						}
					}

					.Workshop-currentItemPrice
					{
						margin-top: 0.5em;
						@include flex('center', 'center');
						background-color: $lightGrayBlue;
						width: 100%;
						border-radius: 0.5em;
						padding: 0.5em;

						span {
							font-weight: bold;
							font-size: 0.9em;
							text-transform: uppercase;
						}
					}

					.Workshop-currentItemUpgrades
					{
						@include flex('center','','row');
						width: 100%;

						.Workshop-upgradeWrapper
						{
							margin: 0.3em;
							margin-top: 1em;
							margin-bottom: 1em;
							
							.Workshop-capacityUpgrade
							{
								@include flex('center','center');
								border-radius: 0.25em;
								width: 1.75em;
								height: 1.75em;

								span
								{
									color: $text-light;
									font-weight: bold;
								}
							}

							.Workshop-groupStatUpgrade
							{
								@include flex('','center','row');
								background-color: $lightGrayBlue;
								border-radius: 1em;

								.Workshop-groupStatIcon
								{
									width: 1.75em;
									height: 1.75em;
									background-size: contain;
	
									@include groupStatIcons();
								}
								.Workshop-groupStatValue
								{
									padding-left: 0.25em;
									padding-right: 0.5em;

									span
									{
										font-weight: bold;
										font-size: 0.9em;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.Workshop-footer
	{
		position: absolute;
		pointer-events: none;
		bottom: 0em;
		@include flex('center','','row');
		width: 100%;
		
		.Workshop-shopPanel
		{
			@include flex('center', '', 'column');
			pointer-events: all;
			width: 55em;
			z-index: 2;

			.Workshop-shopCategoryWrapper
			{
				@include flex('flex-start', '', 'row');
				.Workshop-shopCategory
				{
					cursor: pointer;
					@include flex('', 'center');
					z-index: 2;
					width: 25%;
					margin-right: 0.2em;

					border-top-right-radius: 0.5em;
					border-top-left-radius: 0.5em;

					background-color: $uiGradientDarkBlue;
					@include box-shadow(0em, -0.2em, 0, 0, $uiGradientLightBlue);

					padding-left: 1em;
					padding-top: 0.5em;
					padding-bottom: 0.5em;

					span
					{
						@include no-select();
						font-size: 0.9em;
						font-weight: bold;
						color: $lightBlue;
					}

					&.active {
						z-index: 4;
						background-color: $uiGradientLightBlue;
						@include box-shadow(0em, -0.2em, 0, 0, $uiLightBlue);

						span {
							color: $text-light;
						}
					}

					&.last {
						margin-right: 0em;
					}
				}
			}

			.Workshop-shopBlueBorder 
			{
				z-index: 3;
				width: 100%;
				height: 1em;
				background-color: $uiGradientLightBlue;
				@include box-shadow(0em, -0.2em, 0, 0, $uiLightBlue);
				border-top-right-radius: 0.5em;
			}

			.Workshop-shopInventory 
			{
				@include flex('', '', 'row');
				background-color: $white;
				height: 12em;
				margin-bottom: 2em;

				border-bottom-right-radius: 0.5em;
				border-bottom-left-radius: 0.5em;
				@include box-shadow(0em, 0.4em, 0, 0, $boxBottomGray);

				overflow-y: hidden;

				@include custom-scrollbar(0.5em, $white, $uiGradientLightBlue);
			
				.Workshop-shopItem {
					@include flex('', 'center', 'column');
					margin-top: 1em;
					margin-bottom: 1em;
					margin-left: 1em;

					min-height: 9.5em;
					min-width: 9.5em;

					background-color: $lightGrayBlue;
					border-radius: 0.5em;
					padding: 0.5em;

					cursor: pointer;

					&.last {
						margin-right: 1em; 
					}

					&.current {
						background: radial-gradient($white, $radialGradient1);
						outline: 0.25em solid $uiGradientDarkBlue;
					}

					.Workshop-itemImage
					{
						height: 100%;
						width: 100%;
						background-size: contain;
						background-position-y: bottom;

						@include shopitems();
					}
					.Workshop-itemPrice
					{
						padding-top: 0.25em;
						span
						{
							@include no-select();
						}
					}
				}
			}		
		}

		.Workshop-logoutButton
		{
			position: absolute;
			left: 1em;
			bottom: 1em;
		}
	}
}