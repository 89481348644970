@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Settings {
	overflow-x: hidden;
	overflow-y: hidden;
	
	@include flex('flex-start', '', 'column');
	height: 100%;
	width: 100%;
	position: relative;

	text-align: center;

	.Settings-background {
		position: absolute;
		overflow: hidden;
		z-index: 1;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;

		.Settings-backgroundSvg {
			height: auto;
			width: 140%;
			@include translate(-25em, 23em);
		}
	}

	.Settings-titleWrapper
	{
		@include flex('flex-start', 'center', 'column');
		z-index: 2;
		width: 100%;
		.Settings-title
		{
			margin-top: 3em;
			position: relative;
			width: 100%;
			height: 15.5em;

			background-size: contain;
			background-image: url(../../assets/images/woodlabTitle.svg);
		}
	}

	.Settings-dialogueWrapper
	{
		@include flex('center', '', 'row');
		z-index: 2;
		width: 100%;
		margin-top: 2.5em;
		.Settings-gameWrapper
		{
			@include flex('', 'center', 'column');

			.Settings-game 
			{
				@include flex('space-between', 'center', 'column');
				width: 29em;
				padding: 0.5em;
				background-color: $white;
				border-radius: 0.5em;
				@include box-shadow(0em, 0.4em, 0, 0, $boxBottomGray);

				.Settings-gameHeader
				{
					padding: 2.5em;
					padding-bottom: 1em;
					.Settings-gameHeaderTitle
					{
						padding-bottom: 1.5em;
						span 
						{
							text-transform: uppercase;
							font-size: 1.5em;
							font-weight: bold;
						}
					}
				}

				.Settings-startGameButton {
					width: 10em;
				}
				.Settings-newGameButton {
					margin-top: 0.75em;
					margin-bottom: 0.75em;
					width: 10em;
				}
				.Settings-materialsButton {
					margin-bottom: 2em;
					width: 10em;
				}

				hr {
					width: 100%;
					border: none;
					border-bottom: solid;
					border-width: 0.15em;
					border-color: $darkBlue;
				}
			}
		}
	}

	.Settings-navigation {
		@include flex('space-between', 'center');
		z-index: 2;
		margin: 2.8em;
		margin-top: 0em;
	}
}