@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Login {
	@include flex('flex-start', '', 'column');
	height: 100%;
	width: 100%;
	
	@include groupBackgroundColor();
	@include groupTextColor();

	.Login-titleWrapper
	{
		@include flex('flex-start', 'center', 'column');
		z-index: 2;
		
		.Login-title
		{
			margin-top: 3em;
			position: relative;
			width: 100%;
			height: 15.5em;

			background-size: contain;
			background-image: url(../../assets/images/woodlabTitle.svg);
		}
	}

	.Login-box {
		@include flex('flex-start', 'center', 'column');
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 2;
		margin-top: 1.5em;

		.Login-formTabs
		{
			@include flex('space-between', '', 'row');

			.Login-formTab
			{
				@include flex('center');
				cursor: pointer;
				width: 100%;
				height: 3em;
				border-top-left-radius: 0.5em;
				border-top-right-radius: 0.5em;
				margin-bottom: -0.5em;
				padding: 0.5em;

				border-top: solid;
				border-top-color: $uiLightBlue;
				border-top-width: 0.2em;

				text-transform: uppercase;
				color: $lightBlue;
				background-color: $uiGradientDarkBlue;
				font-weight: bold;

				&.active {
					z-index: 4;
					color: $text-light;
					background-color: $uiGradientLightBlue;
				}
			}
		}

		.Login-form {
			z-index: 3;
			@include flex('space-between', 'center', 'column');
			position: relative;
			
			height: 18em;
			padding: 2em;
			padding-bottom: 0.5em;
			border-radius: 0.5em;

			border-top: solid;
			border-top-color: $uiLightBlue;
			border-top-width: 0.2em;

			border-bottom: solid;
			border-bottom-color: $uiDarkBlue;
			border-bottom-width: 0.3em;
			
			background-image: linear-gradient(
				to bottom,
				$uiGradientLightBlue 10%,
				$uiGradientDarkBlue 40%
			);

			&.feedback {
				.Login-infoLink {
					color: white;
					cursor: pointer;
					&:hover {text-decoration: underline;}
				}
			}
			// Fixing chrome setting the background color to white on autofill
			input:-webkit-autofill {
				// For some reason, chrome does not allow background-color on autofill
				// This is solved here by settings a large inset box-shadow with the desired background color
				box-shadow: 0 0 0 10em $lightBlue inset !important;
				-webkit-text-fill-color: $darkBlue;
			}
			input {
				display: block;
				width: 18em;
				height: 2em;
				
				border: none;
				border-radius: 0.35em;
				
				margin: auto;
				margin-bottom: 1em;
				padding-left: 1em;
				
				background-color: $lightBlue;
				color: $darkBlue;

				&::placeholder {color: $darkBlue;}
			}
			.Login-header {
				span 
				{	
					color: $text-light;
					font-weight: bold;
				}

				.Login-inputWrapper
				{
					margin-top: 0.5em;
				}
			}
			.Login-spacing {
				height: 1.6em;
			}

			.Login-userButton {
				cursor: pointer;
				color: $text-light;
				font-size: 0.9em;
			}
			.Login-userButton:hover {
				text-decoration: underline;
			}

			.Login-gameCodeMessage {
				line-height: 1.2em;
				width: 16em;
				color: $text-light;
				text-align: center;
			}

			.Login-info {
				color: $text-light;
				width: 12em;
			}
		}

		.LoginFacilitator,
		.LoginGroup
		{
			@include align-self(center);
		}

		.LoginFacilitator {
			.Login-createUser {
				@include flex('center', 'center', 'column');
				width: 29em;
				border-radius: 0.5em;
				padding: 3em;
				background-color: $white;
				@include box-shadow(0em, 0.4em, 0, 0, $boxBottomGray);

				.Login-info
				{
					color: $text-dark;
				}

				.Login-backToLoginButton
				{
					@include flex('center');
					margin-top: 3.5em;
					width: 10em;
				}
			}
		}

		.LoginChooseGroup
		{
			@include flex('center', 'center', 'column');
			padding: 1em;
			width: 100%;
			height: 100%;

			.LoginChooseGroup-cornerLogo 
			{
				position: absolute;
				left: 1em;
				top: 1em;
			}

			.LoginChooseGroup-title {
				padding-bottom: 1em;
				text-transform: uppercase;
				span {
					font-size: 1.4em;
					font-weight: 900;
				}
			}

			.LoginChooseGroup-groupsWrapper
			{
				@include flex('flex-end', '', 'column');
				height: 18em;
				width: 100%;

				.Login-groups
				{
					@include flex('space-between', '', 'row');
					margin-bottom: 2em;

					.Login-groupSelect
					{
						@include flex('flex-end', 'center', 'column');
					}
				}
			}
		}
	}
}

.Login-buttons {
	@include flex('space-between', 'center');
	.Button.Button--login { 
		margin: 0 0.5em;
		&:nth-child(1) {margin-left: 0;}
		&:nth-last-child(1) {margin-right: 0;}
	}
}

.Login-errorMessage {
	pointer-events: none;
	height: 1.5em;
	margin: 0.6em;
	margin-bottom: 1.5em;
	width: 12em;
	font-size: 0.85em;
	color: red;
	text-align: center;
}