@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.GameIntro
{
	z-index: 2;
	position: relative;
	overflow: hidden;
	height: 100%;
	width: 100%;

	.GameIntro-videoPopup {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba($overlayBlue, 0.5);
		z-index: 99;

		@include flex('center', 'center');
		
		.GameIntro-videoWrapper {
			position: relative;
			background-color: $white;
			border-radius: 0.5em;
			height: 43.35em;
			width: 70.7em;
			@include flex('center', 'center');

			.GameIntro-closeButton {
				position: absolute;
				background-image: url(../../../../assets/images/icons/icon-close.svg);
				background-size: contain;
				width: 1.9em;
				height: 1.9em;
				top: 0.75em;
				right: 0.75em;
				cursor: pointer; 
			}

			.GameIntro-video {
				width: 64.1em;
				height: 36.1em;
				border-radius: 0.5em;
			}
		}
	}

	.GameIntro-startVideoButton {
		position: absolute;
		top: 4em;
		left: calc(50% - (10.8em / 2));
		background-color: $uiGradientLightBlue;
		width: 10.8em;
		height: 6.65em;
		border-radius: 0.5em;

		cursor: pointer;

		@include box-shadow(0, 0.5em, 0, 0, $blue);
		border-top-color: $boxBottomGray;
		border-top-style: solid;
		border-top-width: 0.2em;

		@include flex('center', 'center', 'column');

		@include transition(0.1s);

		&:hover {
			top: 3.65em;
			@include box-shadow(0, 0.85em, 0, 0, $blue);
		}

		&:active {
			top: 4.25em;
			@include box-shadow(0, 0.25em, 0, 0, $blue);
		} 

		.GameIntro-videoIcon {
			background-image: url(../../../../assets/images/icons/icon-startVideo.svg);
			background-size: contain;
			width: 3em;
			height: 3em;
			margin-bottom: 0.8em;
		}

		span {
			color: $white;
			font-weight: bold;
		}
	}

	.GameIntro-pageWrapper
	{
		@include flex('space-between', 'center', 'column');
		padding: 1em;
		height: 100%;
		width: 100%;

		.GameIntro-gameCode
		{
			@include flex('flex-start');
			width: 100%;
		}

		.GameIntro-groupsWrapper
		{
			width: 100%;
			.GameIntro-groups
			{
				@include flex('center', '', 'row');
				height: 17em;
				width: 100%;

				.GameIntro-group 
				{
					@include transition(0.25s, 'opacity');

					width: 13em;
					height: 11em;
					margin-left: 1em;
					margin-right: 1em;
				}
			}
		}

		.GameIntro-startButton
		{
			margin-bottom: 5em;
		}
	}
}