@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Progressbar
{
	position: relative;
	width: 100%;
	z-index: 3;

	.Progressbar-round1
	{
		width: 100%;

		#color-fill-2,
		#color-fill-3,
		#color-fill-4,
		#color-fill-5,
		#color-fill-6
		{
			display: none;
		}

		#text-2,
		#text-3,
		#text-4,
		#text-5,
		#text-6
		{
			opacity: 0.6;
		}
	}
	.Progressbar-round2
	{
		width: 100%;
		
		#color-fill-3,
		#color-fill-4,
		#color-fill-5,
		#color-fill-6
		{
			display: none;
		}

		#text-3,
		#text-4,
		#text-5,
		#text-6
		{
			opacity: 0.6;
		}
	}
	.Progressbar-round3
	{
		width: 100%;
		
		#color-fill-4,
		#color-fill-5,
		#color-fill-6
		{
			display: none;
		}

		#text-4,
		#text-5,
		#text-6
		{
			opacity: 0.6;
		}
	}
	.Progressbar-round4
	{
		width: 100%;
		
		#color-fill-5,
		#color-fill-6
		{
			display: none;
		}

		#text-5,
		#text-6
		{
			opacity: 0.6;
		}
	}
	.Progressbar-round5
	{
		width: 100%;
		
		#color-fill-6
		{
			display: none;
		}

		#text-6
		{
			opacity: 0.6;
		}
	}
	.Progressbar-round6
	{
		width: 100%;
	}
}