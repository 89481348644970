@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.OrderDndContainer {
	position: relative;
	border-radius: 0.55em;
	background-color: $lightGrayBlue;

	&.hover {
		@include opacity(0.6);
	}
}