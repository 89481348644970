@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Button {
	z-index: 2;
	position: relative;
	display: inline-block;
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	color: black;
	background-color: white;
	padding: 0.1em 0.5em;
	border-radius: 0.125em;
	outline: none;
	touch-action: manipulation;
	pointer-events: auto;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	&:hover,
	&:focus,
	&:active {
		background-color: darken(white, 5%);
	}
	&.disabled {
		opacity: 0.4;
		cursor: not-allowed;
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.continue {
		color: $darkBlue;
		background-color: $offWhite;
		
		border-radius: 0.5em;
		padding-bottom: 0.8em;
		padding-top: 0.8em;
		width: 17em;

		@include box-shadow(0.3em, 0.3em, 0.1em, 0, $boxShadow);

		span {
			font-size: 1.25em;
			font-weight: 500;
		}
	}
	&.popup {
		@include flex('center','center');
		background-color: $uiGradientLightBlue;
		color: $text-light;

		border-radius: 0.3em;
		
		padding-left: 2em;
		padding-right: 2em;
		padding-top: 0.4em;
		padding-bottom: 0.4em;

		span {
			font-size: 1em;
		}

		&.empty {
			padding-left: 1em;
			padding-right: 1em;

			border: solid;
			border-width: 0.15em;
			color: $uiGradientLightBlue;
			
			background-color: $white;
			
			span {
				font-size: 0.9em;
			}
		}
	}
	&.loading {
		background-image: url('../../../assets/images/icons/icon-loading.svg');
		background-size: auto 80%, 100%;
		background-position: center right, center center;
		background-repeat: no-repeat;
	}
	&.popup:hover {
		@include transition(0.25s, 'all');
		background-color: $lightBlueHighlight;

		&.empty {
			background-color: $uiGradientLightBlue;
			border-color: $uiGradientLightBlue;
			color: $text-light;
		}
	}
	&.logout {
		@include flex('flex-end', 'center');
		background-color: $uiGradientLightBlue;
		color: $text-light;
		z-index: 4;
		border-radius: 0.5em;
		width: 9em;
		height: 2.8em;
		padding-right: 1.33em;

		background-image: url('../../../assets/images/icons/icon-logout.svg');
		background-size: auto 50%, 100%;
		background-position: left 0.25em bottom calc(50%);

		span {
			text-transform: uppercase;
			font-size: 1.2em;
		}
		
		&:hover {
			background-color: $lightBlueHighlight;
		}
	}
	&.cookies,
	&.login {
		@include transition(0.1s, 'background-color');
		@include flex('center', 'center');
		
		width: 10em;
		
		border-radius: 0.5em;
		padding: 0.25em;
		margin-top: 1em;

		font-weight: bold;
		color: $text-light;
		background-color: $uiGradientLightBlue;

		&.submit {
			border: none;
			font-size: 1em;
		}
	}
	&.fullHeight {
		height: 100%;
	}
	&.noTopMargin {
		margin-top: 0em;
	}
	button {
		border: none;
	}
	&.login:hover {
		background-color: $lightBlueHighlight;
	}
	&.flatBlue {
		@include transition(0.1s, 'background-color');
		@include flex('center', 'center');

		width: 100%;
		
		border-radius: 0.5em;
		padding: 0.75em;

		font-weight: bold;
		color: $text-light;
		background-color: $uiGradientLightBlue;

		span {
			font-size: 0.9em;
		}
		
		&:hover {
			background-color: $lightBlueHighlight;
		}
		
		&.animateWrong {
			background-color: $wrongAnswer;
			color: $text-light;
		
			@include transition(0.1s, 'all');
			@include animateWrong();
			cursor: not-allowed;
		}
		
		&.animateCorrect {
			background-color: $correctAnswer;
			color: $text-light;
		
			@include transition(0.1s, 'all');
			@include animateCorrect();
			cursor: not-allowed;
		}

		&.download {
			background-image: url(../../../assets/images/icons/icon-download.svg);
			background-size: contain;
			background-position: right 0.2em top 0em;
			background-origin: content-box;
		}
	}
	&.pushable {
		@include pushable-button();
	}

	&.medium {
		width: 12em;
	}
}