@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.SpotErrors {
	position: relative;
	@include flex('','','column');
	height: 100%;
	overflow: auto;
	
	.SpotErrors-text {
		position: relative;
		margin-top: 2em;
		width: 100%;
		height: auto;
		span {
			color: $text-dark;
			font-size: 0.85em;
		}
	}

	.SpotErrors-errors {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;

		.SpotErrors-error {
			position: absolute;
			width: 5em;
			height: 5em;
			border-radius: 100%;
			border: 0 solid $darkBlue;
			&.spotted {
				background-image: url('../../../../assets/images/icons/icon-checkmark2.svg');
				background-size: 1.4em auto;
				background-position: center center;
			}
		}

		&.badebro {
			background-image: url('../../../../assets/images/assignments/badebro.svg');
			position: relative;
			margin: auto;
			height: 100%;
			width: 100%;
			margin-top: 0.5em;
			background-size: 100% 25em;
			background-position: bottom 0 left calc(50%);
			.SpotErrors-error.tegningshoved
			{
				bottom: 0em;
				border-radius: 0;
				left: calc(50% + 8.3em);
				width: 9.9em;
				height: 11.1em;
			}
		}

		&.tilbygning {
			background-image: url('../../../../assets/images/assignments/tilbygning.svg');
			position: relative;
			margin: auto;
			height: 100%;
			width: 100%;
			margin-top: 0.5em;
			background-size: 100% 25em;
			background-position: bottom 0 left calc(50%);
			.SpotErrors-error.tegningshoved
			{
				bottom: 0em;
				border-radius: 0;
				left: calc(50% + 8.7em);
				width: 9.6em;
				height: 15em;
			}
		}

		&.skaterRampe {
			background-image: url('../../../../assets/images/assignments/skateboardrampe.svg');
			position: relative;
			margin: auto;
			height: 100%;
			width: 100%;
			margin-top: 0.5em;
			background-size: 100% 25em;
			background-position: bottom 0 left calc(50%);
			.SpotErrors-error.tegningshoved
			{
				bottom: 0em;
				border-radius: 0;
				left: calc(50% + 7.3em);
				width: 10.8em;
				height: 15em;
			}
		}

		&.binding {
			background-image: url('../../../../assets/images/assignments/bindingsvaerk.svg');
			position: relative;
			margin: auto;
			height: 100%;
			width: 100%;
			margin-top: 0.5em;
			background-size: 100% 25em;
			background-position: bottom 0 left calc(50%);
			.SpotErrors-error.tegningshoved
			{
				bottom: 0.2em;
				border-radius: 0;
				left: calc(50% + 8.2em);
				width: 9.8em;
				height: 13.8em;
			}
		}

		&.typehus {
			background-image: url('../../../../assets/images/assignments/typehus.svg');
			position: relative;
			margin: auto;
			height: 100%;
			width: 100%;
			margin-top: 0.5em;
			background-size: 100% 25em;
			background-position: bottom 0 left calc(50%);
			.SpotErrors-error.tegningshoved
			{
				bottom: 0em;
				border-radius: 0;
				left: calc(50% + 8em);
				width: 10.3em;
				height: 16.6em;
			}
		}

		&.terrasse {
			background-image: url('../../../../assets/images/assignments/traeterrasse.svg');
			position: relative;
			margin: auto;
			height: 100%;
			width: 100%;
			margin-top: 0.5em;
			background-size: 100% 25em;
			background-position: bottom 0 left calc(50%);
			.SpotErrors-error.tegningshoved
			{
				bottom: 0.6em;
				border-radius: 0;
				left: calc(50% + 8.4em);
				width: 9.5em;
				height: 16.4em;
			}
		}

		&.gips {
			background-image: url('../../../../assets/images/assignments/gipsvaegge.svg');
			position: relative;
			margin: auto;
			height: 100%;
			width: 100%;
			margin-top: 0.5em;
			background-size: 100% 25em;
			background-position: bottom 0 left calc(50%);
			.SpotErrors-error.tegningshoved
			{
				bottom: 0em;
				border-radius: 0;
				left: calc(50% + 8.4em);
				width: 9.8em;
				height: 14em;
			}
		}

		&.former {
			background-image: url('../../../../assets/images/assignments/geometry/former.svg');
			position: relative;
			margin: auto;
			height: 100%;
			width: 100%;
			margin-top: 0.5em;
			background-size: 100% 25em;
			background-position: bottom 0 left calc(50%);
			.SpotErrors-error.billede6
			{
				bottom: 8.5em;
				left: calc(50% - 5em);
				width: 10em;
				height: 8em;
			}
		}
	}


	.SpotErrors-buttonWrapper
	{
		width: 100%;
		height: 3em;
		margin-top: 0.5em;
		@include flex('flex-end', 'flex-end');

		.SpotErrors-confirmBtn {
			position: relative;
			width: 15em;
			span {
				font-weight: bold;
			}
		}
	}

	&.completed {
		.SpotErrors-error:not(.spotted) {
			border-width: 0.25em;
		}
	}
}