@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Order {
	position: relative;
	height: 100%;
	width: 100%;
	padding-top: 2em;
	padding-bottom: 1em;
	overflow: hidden;
	.Order-intro {
		position: relative;
	}

	.Order-items {
		position: absolute;
		width: 100%;
		bottom: 3em;
		margin: 1em auto auto auto;
		overflow: auto;
		@include hide-scrollbar();
		@include flex('flex-start', 'center', 'column');
		.Order-item {
			width: 100%;
			width: 17.5em;
			margin-bottom: 1em;
			@include flex('space-between', 'center');

			.Order-itemNumber {
				@include flex('center', 'center');
				width: 2em;
				height: 2em;
				background-color: $lightGrayBlue;
				margin-right: 0.5em;
				border-radius: 0.5em;
				span {
					font-size: 1.2em;
					font-weight: bold;
				}
			}
			.Order-itemContainer {
				width: calc(100% - 2em);
			}
		}
	}

	.Order-doneBtn {
		position: absolute;
		right: 0em;
		bottom: 0em;
		z-index: 2;
		width: 10em;
	}
}