@-webkit-keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeOut {
	0% {@include opacity(1);}
	100% {@include opacity(0);}
}

@keyframes floatRight {
	0% {transform: translateX(-35%);}
	100% {transform: translateX(120%);}
}

@keyframes floatLeft {
	0% {transform: translateX(120%);}
	100% {transform: translateX(-20%);}
}

@keyframes fadeInOut {
	0% {@include opacity(0);}
	50% {@include opacity(1);}
	100% {@include opacity(0);}
}

@keyframes bounce {
	0% {transform: translateY(0em);}
	25% {transform: translateY(-1em);}
	30% {transform: translateY(0em);}
	55% {transform: translateY(-0.75em);}
	60% {transform: translateY(0em);}
	85% {transform: translateY(-0.5em);}
	90% {transform: translateY(0em);}
	95% {transform: translateY(-0.25em);}
	100% {transform: translateY(0em);}
}



@-webkit-keyframes wobble {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	15% {
		-webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
		transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
	}

	30% {
		-webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
		transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
	}

	45% {
		-webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
		transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
	}

	60% {
		-webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
		transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
	}

	75% {
		-webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
		transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes wobble {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	15% {
		-webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
		transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
	}

	30% {
		-webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
		transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
	}

	45% {
		-webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
		transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
	}

	60% {
		-webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
		transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
	}

	75% {
		-webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
		transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}