@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Popup
{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	.Popup-overlay
	{
		position: absolute;
		background-color: $overlayBlue;
		height: 100%;
		width: 100%;
		z-index: 90;
		opacity: 0.5;
	}

	.Popup-dialogue
	{
		@include flex('center', 'center', 'column');
		position: absolute;
		width: 100%;
		height: 100%;

		.Popup-dialogueBox
		{
			@include flex('space-between', '', 'column');
			position: relative;
			z-index: 99;
			border-radius: 0.4em;
			color: $text-dark;
			background-color: $white;
			padding: 2.5em;
			@include box-shadow(0em, 0.5em, 0, 0, $boxBottomGray);

			&.center
			{
				text-align: center;
			}
	
			&.left
			{
				text-align: left;
			}

			.Popup-title{
				font-size: 1.5em;
				font-weight: bold;
				margin-bottom: 0.5em;
			}

			.Popup-message{
				margin-bottom: 2em;
				line-height: 1.3em;
				width: 20em;

				&.rules {
					width: 40em;
				}
			}

			.Popup-buttons{
				@include flex('space-between', 'space-between', 'row');
				width: 100%;
			}

			.Popup-button {
				@include flex('center');
				width: 100%;
			}
		}
	}
}