@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GameSettingsPopup {
	@include flex('center', 'center');
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 5;
	background-color: rgba($overlayBlue, 0.5);

	.GameSettingsPopup-dialogueWrapper {
		@include box-shadow(0, 0.45em, 0.25em, 0.2em, rgba($darkBlue, 0.4));
		border-radius: 0.5em;
		
		.GameSettingsPopup-dialogue {
			@include flex('', 'center', 'column');
			width: 29em;
			height: 100%;
			padding-top: 2em;
			padding-bottom: 1.8em;
			background-color: $white;
			border-radius: 0.5em;
			@include box-shadow(0, 0.45em, 0, 0, $boxBottomGray);
			
			.GameSettingsPopup-dialogueTitle {
				span {
					font-size: 1.6em;
					font-weight: 900;
					text-transform: uppercase;
				}
			}

			.GameSettingsPopup-settings {
				.GameSettingsPopup-settingsTitle {
					margin-top: 1.1em;
					margin-bottom: 0.4em;
					span {
						font-weight: 600;
					}
				}
				.GameSettingsPopup-setting {
					@include flex('center', 'center');
					cursor: pointer;
					width: 17.25em;
					height: 2.5em;
					border-style: solid;
					border-color: $lightGrayBlue;
					border-width: 0.2em;
					border-radius: 0.4em;
					margin-bottom: 0.3em;

					span {
						font-weight: 600;
					}

					&.current {
						background-color: $lightGrayBlue;

						background-image: url(../../../assets/images/icons/icon-checkmark3.svg);
						background-position: top calc(50%) right 0.8em;
					}

					&:hover {
						border-color: $boxBottomGray;
					}
				}

				.GameSettingsPopup-combinedTime {
					padding-top: 1.7em;
					padding-bottom: 1.5em;
				}
			}

			.GameSettingsPopup-button {
				width: 10.8em;
			}
		}
	}
}