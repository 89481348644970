@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FinalAchievement {
	height: 100%;
	@include flex('flex-start', 'center', 'column');
	@include groupBackgroundColor();
	position: relative;
	z-index: 2;
	padding-bottom: 7em;

	.FinalAchievement-headerWrapper {
		@include flex('center', 'flex-start', 'row');
		width: 100%;
		
		.FinalAchievement-gameCode {
			margin: 1em;
			position: absolute;
			left: 0;
			top: 0;
		}
		.FinalAchievement-progressbar {
			margin: 1em;
			width: 60em;
			position: relative;
		}
	}

	.FinalAchievement-scoreboardWrapper {
		@include flex('center', 'center');
		.FinalAchievement-scoreboard {
			background-color: $white;
			padding: 1em;
			width: 57.9em;
			border-radius: 0.5em;
			.FinalAchievement-scoreboardTitle {
				width: 100%;
				@include flex('center', 'center');
				border-bottom-style: solid;
				border-bottom-color: $boxBottomGray;
				border-bottom-width: 0.15em;
				padding-bottom: 1em;
				margin-bottom: 0.5em;

				span {
					font-size: 1.2em;
					font-weight: bold;
					text-transform: uppercase;
				}
			}		

			.FinalAchievement-scoreboardHeader {
				@include flex('', 'center');

				.FinalAchievement-scoreboardHeaderTitle {
					text-transform: uppercase;
					margin-bottom: -0.25em;

					&.first {
						margin-right: 1.8em;
						margin-left: 1.2em;
						span {
							font-weight: bold;
							font-size: 0.9em;
							@include flex('flex-start', 'center');
						}
					}
					&.start {
						width: 100%;
						span {
							font-weight: bold;
							font-size: 0.9em;
							@include flex('flex-start', 'center');
						}
					}
					&.end {
						margin-right: 11em;
						span {
							font-weight: bold;
							font-size: 0.9em;
							@include flex('center', 'center');
						}
					}
				}
			}

			.FinalAchievement-groupScoreWrapper {
				@include flex('space-between', 'center', 'row');
				position: relative;
				height: 100%;

				border: solid;
				border-width: 0.2em;
				border-color: $boxBottomGray;
				border-radius: 0.5em;
				
				margin-top: 0.5em;
				padding: 0.25em;
				padding-left: 0.25em;
				.FinalAchievement-groupRank {
					@include flex('center', 'center');
					width: 3em;
					height: 3em;
					border-radius: 0.2em;
					background-color: $lightGrayBlue;
					margin-right: 0.75em;

					&.gold {
						background-color: $gold;
					}

					span {
						color: $text-dark;
						font-weight: bold;
						font-size: 1.2em;
					}
				}
				.FinalAchievement-groupNameWrapper {
					width: 26.25em;
					height: 3em;
					@include flex('flex-start','center');
					font-weight: bold;

					color: $boxBottomGray;
					border-left-style: solid;
					border-right-style: solid;
					border-width: 0.15em;
					padding-left: 0.5em;
					
					.FinalAchievement-groupName {
						width: 14.5em;
						span {
							color: $text-dark;
							font-weight: bold;
							font-size: 1.2em;
						}	
					}

					.FinalAchievement-otherAchievements {
						width: 2.7em;
						height: 2.5em;
						background-size: contain;
						margin-left: 0.5em;
						&.quality {
							background-image: url('../../../assets/images/icons/icon-quality-gold.svg');
						}
						&.workenvironment {
							background-image: url('../../../assets/images/icons/icon-workenvironment-gold.svg');
						}
						&.sustainability {
							background-image: url('../../../assets/images/icons/icon-sustainability-gold.svg');
						}
					}
				}
				.FinalResult-groupStat {
					width: 25em;
					margin-top: -0.25em;
					margin-bottom: -0.25em;

					@include flex('center','center');
					.FinalResult-groupStatIcon {
						@include flex('flex-end', 'center');
						width: 5.75em;
						height: 3em;
						background-color: $lightGrayBlue;
						border-radius: 1.5em;
						padding-right: 0.75em;

						&.quality {
							&.gold {
								background-image: url('../../../assets/images/icons/icon-quality-gold.svg');
								background-size: contain;
								background-position: left 0em top calc(50%);
							}
							&.none {
								background-image: url('../../../assets/images/icons/icon-quality-none.svg');
								background-size: contain;
								background-position: left 0em top calc(50%);
							}
						}
						&.workenvironment {
							&.gold {
								background-image: url('../../../assets/images/icons/icon-workenvironment-gold.svg');
								background-size: contain;
								background-position: left 0em top calc(50%);
							}
							&.none {
								background-image: url('../../../assets/images/icons/icon-workenvironment-none.svg');
								background-size: contain;
								background-position: left 0em top calc(50%);
							}
						}
						&.sustainability {
							&.gold {
								background-image: url('../../../assets/images/icons/icon-sustainability-gold.svg');
								background-size: contain;
								background-position: left 0em top calc(50%);
							}
							&.none {
								background-image: url('../../../assets/images/icons/icon-sustainability-none.svg');
								background-size: contain;
								background-position: left 0em top calc(50%);
							}
						}

						span {
							color: $text-dark;
							font-weight: bold;
							font-size: 1.2em;
						}
					}
				}
				.FinalAchievement-cornerWrapper {
					position: absolute;
					// Compensating for gap
					top: -0.15em;
					right: -0.15em;
					clip-path: polygon(100% 100%, 100% 0, 0 0);
					.FinalAchievement-corner {
						position: relative;
						box-shadow: inset 0.2em 0.2em 0.3em $boxShadow;
						height: 3em;
						width: 3em;
						border-top-right-radius: 0.5em;
					}
				}
			}
		}
	}

	.FinalAchievement-continueButton {
		position: absolute;
		bottom: 3em;
	}
}