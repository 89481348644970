@import url("https://use.typekit.net/iab4jpr.css");

/*
Neo Sans W1G Regular

font-family: neo-sans, sans-serif;

font-weight: 400;

font-style: normal;

-------------------------------------

Neo Sans W1G Medium

font-family: neo-sans, sans-serif;

font-weight: 500;

font-style: normal;

-------------------------------------

Neo Sans W1G Bold

font-family: neo-sans, sans-serif;

font-weight: 700;

font-style: normal;

-------------------------------------

Neo Sans W1G Black

font-family: neo-sans, sans-serif;

font-weight: 900;

font-style: normal;
*/
