@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.GameOver {
	@include flex('space-between', 'center', 'column');
	position: relative;
	height: 100%;
	z-index: 2;
	padding-bottom: 2.8em;

	.GameOver-headerWrapper {
		@include flex('space-between', '', 'row');
		width: 100%;

		.GameOver-gameCode {
			width: 10%;
			
		}
		.GameOver-progressbar {
			margin: 1em;
			
		}
		.GameOver-spacer {
			width: 10%;
		}
	}
	
	.GameOver-infoWrapper {
		position: relative;
		width: 29em;
		height: 8.15em;
		background-color: $boxBottomGray;
		border-radius: 0.5em;
		box-shadow: 0.2em 0.2em 0.2em rgba($text-dark, 0.4);

		.GameOver-info {
			@include flex('center', 'center');
			position: absolute;
			height: 7.7em;
			width: 100%;
			background-color: $white;
			border-radius: 0.5em;

			.GameOver-text {
				span {
					text-transform: uppercase;
					font-weight: 900;
					font-size: 1.6em;
				}
			}
		}
	}
}

