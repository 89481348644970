@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Attention 
{
	@include flex('center', 'center', 'column');
	position: relative;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	padding: 1em;
	z-index: 2;

	.Attention-cornerLogo {
		position: absolute;
		left: 2em;
		top: 2em;
	}

	.Attention-textWrapper {
		position: relative;
		z-index: 2;
		height: 8em;
		border-radius: 0.5em;
		box-shadow: 0.2em 0.2em 0.2em rgba($text-dark, 0.4);

		.Attention-text
		{
			@include flex('center', 'center', 'column');
			background-color: $offWhite;
			height: 7.7em;
			width: 29em;

			border-radius: 0.5em;
			box-shadow: 0em 0.3em $boxBottomGray;

			span
			{
				font-size: 2em;
				font-weight: bold;
				color: $text-dark;
				text-transform: uppercase;
			}
		}
	}
	.Attention-logoutButton
	{
		@include flex('', 'flex-end');
		position: relative;
		z-index: 2;
	}
}