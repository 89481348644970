// APPEARANCE
@mixin appearance($value) {
	-webkit-appearance: #{$value};
	-moz-appearance: #{$value};
	appearance: #{$value};
}

// TOUCH ACTION
@mixin touchaction($value) {
	touch-action: #{$value};
	-ms-touch-action: #{$value};
}

@mixin placeholderColor($color) {
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: $color;
		opacity: 1; /* Firefox */
	}
	
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: $color;
	}
	
	&::-ms-input-placeholder { /* Microsoft Edge */
		color: $color;
	}
}

/* Autofill state for inputs */
@mixin autofilled($color, $bgColor) {
	-webkit-animation-name: autofill;
	-webkit-animation-fill-mode: both;
	animation-name: autofill;
	animation-fill-mode: both;
	@keyframes autofill {
		to {
			color: $color;
			background-color: $bgColor;
		}
	}
}

// FLEX
@mixin flex($justify: '', $align: '', $direction: '') {
	display: -webkit-box; // OLD - iOS 6-, Safari 3.1-6
	display: -moz-box; // OLD - Firefox 19- (buggy but mostly works)
	display: -ms-flexbox; // TWEENER - IE 10
	display: -webkit-flex; // NEW - Chrome
	display: flex; // NEW, Spec - Opera 12.1, Firefox 20+
	@include flex-properties($justify, $align, $direction);
}

@mixin inline-flex($justify: '', $align: '', $direction: '') {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
	@include flex-properties($justify, $align, $direction);
}

@mixin flex-properties($justify, $align, $direction) {
	@if $justify != '' {
		-webkit-justify-content: #{$justify};
		justify-content: #{$justify};
		-webkit-box-pack: #{$justify};
		-ms-flex-pack: #{$justify};
	}
	@if $align != '' {
		-webkit-box-align: #{$align};
		-webkit-flex-align: #{$align};
		-ms-flex-align: #{$align};
		-webkit-align-items: #{$align};
		align-items: #{$align};
	}
	@if $direction != '' {
		-webkit-box-direction: #{$direction};
		-webkit-flex-direction: #{$direction};
		flex-direction: #{$direction};
	}
}

@mixin flex-wrap($wrap) {
	-webkit-flex-wrap: $wrap; /* Safari 6.1+ */
	flex-wrap: $wrap;
}

@mixin align-content($align) {
	-webkit-align-content: $align;
	align-content: $align;
}

@mixin align-self($value) {
	-webkit-align-self: #{$value};
	align-self: #{$value};
}

// SCALE
@mixin scale($scale) {
	-ms-transform: scale($scale); /* IE 9 */
	-webkit-transform: scale($scale); /* Safari */
	transform: scale($scale);
}

// ROTATE
@mixin rotate($angle) {
	-ms-transform: rotate($angle); /* IE 9 */
	-webkit-transform: rotate($angle); /* Safari */
	transform: rotate($angle);
}

// TRANSITION
@mixin transition($seconds: '1s', $target: 'all', $type: 'linear') {
	-webkit-transition: #{$target} $seconds #{$type}; /* WebKit */
	-moz-transition: #{$target} $seconds #{$type}; /* Firefox */
	-o-transition: #{$target} $seconds #{$type}; /* Opera */
	-ms-transition: #{$target} $seconds #{$type}; /* MS */
	transition: #{$target} $seconds #{$type}; /* Standard */
}

@mixin transitionTransform($seconds, $type) {
	-webkit-transition: -webkit-transform $seconds #{$type}; /* WebKit */
	-moz-transition: -moz-transform $seconds #{$type}; /* Firefox */
	-o-transition: -o-transform $seconds #{$type}; /* Opera */
	-ms-transition: -ms-transform $seconds #{$type}; /* MS */
	transition: transform $seconds #{$type}; /* Standard */
}


@mixin transition-delay($time) {
	-webkit-transition-delay: $time;
	-moz-transition-delay: $time;
	-o-transition-delay: $time;
	transition-delay: $time;
}

@mixin animationTimingFunction($type) {
	-webkit-animation-timing-function: #{$type};
	animation-timing-function: #{$type};
}

@mixin translate($x, $y: 0) {
	-webkit-transform: translate($x, $y);
	-moz-transform: translate($x, $y);
	-ms-transform: translate($x, $y);
	-o-transform: translate($x, $y);
	transform: translate($x, $y);
}

@mixin translate3d($x: 0, $y: 0, $z: 0) {
	-ms-transform: translate3d($x, $y, $z);
	-webkit-transform: translate3d($x, $y, $z);
	transform: translate3d($x, $y, $z);
}

@mixin translateAndRotate($x: 0, $y: 0, $z: 0, $angle: 0) {
	-ms-transform: translate3d($x, $y, $z) rotate($angle);
	-webkit-transform: translate3d($x, $y, $z) rotate($angle);
	transform: translate3d($x, $y, $z) rotate($angle);
}

@mixin origin($x, $y) {
	-webkit-transform-origin: $x $y;
	transform-origin: $x $y;
}

@mixin flip($x: 1, $y: 1) {
	-moz-transform: scaleX($x) scaleY($y);
	-o-transform: scaleX($x) scaleY($y);
	-webkit-transform: scaleX($x) scaleY($y);
	transform: scaleX($x) scaleY($y);
}

// OPACITY
@mixin opacity($opacity) {
	$procentOpacity: $opacity * 100;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity='
		$procentOpacity ')'; /* IE 8 */
	filter: alpha(opacity=$procentOpacity); /* IE 5-7 */
	-moz-opacity: $opacity; /* Netscape */
	-khtml-opacity: $opacity; /* Safari 1.x */
	opacity: $opacity;
}

// INPUT
@mixin input-placeholder($color) {
	input::-webkit-input-placeholder {
		color: $color !important;
	} /* Chrome/Opera/Safari */
	input:-moz-placeholder {
		color: $color !important;
	} /* Firefox 18- */
	input::-moz-placeholder {
		color: $color !important;
	} /* Firefox 19+ */
	input:-ms-input-placeholder {
		color: $color !important;
	} /* IE 10+ */
}

// TEXTAREA
@mixin textarea-placeholder($color) {
	textarea::-webkit-input-placeholder {
		color: $color !important;
	} /* Chrome/Opera/Safari */
	textarea:-moz-placeholder {
		color: $color !important;
	} /* Firefox 18- */
	textarea::-moz-placeholder {
		color: $color !important;
	} /* Firefox 19+ */
	textarea:-ms-input-placeholder {
		color: $color !important;
	} /* IE 10+ */
}

// NO-SELECT
@mixin no-select() {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently*/
}

// SCROLLBARS
/* HIde scrollbar */
@mixin hide-scrollbar() {
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin floatRight($duration: 5s, $delay: 0s) {
	animation: floatRight $duration $delay linear infinite;
	-webkit-animation: floatRight $duration $delay linear infinite;
}

@mixin floatLeft($duration: 5s, $delay: 0s) {
	animation: floatLeft $duration $delay linear infinite;
	-webkit-animation: floatLeft $duration $delay linear infinite;
}


// CUSTOM SCROLLBAR
@mixin custom-scrollbar($width, $backgroundColor, $thumbColor) {
	/*Chrome, Safari and Edge*/
	&::-webkit-scrollbar {
		width: $width; /* width of the veritcal scrollbar */
		height: $width; /* width of the horizontal scrollbar */
	}
	&::-webkit-scrollbar-track {
		border-radius: $width;
		background-color: $backgroundColor;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $thumbColor;
		border-radius: $width;
		border: 1px solid $backgroundColor;
	}
	&::-webkit-scrollbar-corner {
		background-color: rgba(0, 0, 0, 0);
	}
	/*Firefox*/
	scrollbar-width: thin;
	scrollbar-color: $thumbColor $backgroundColor;
}

@mixin rotationStyle($style) {
	-webkit-transform-style: #{$style};
	-moz-transform-style: #{$style};
	transform-style: #{$style};
}

// BOX SHADOW
@mixin box-shadow($hoffset, $voffset, $blur, $spread, $color) {
	-webkit-box-shadow: $hoffset $voffset $blur $spread $color;
	-moz-box-shadow: $hoffset $voffset $blur $spread $color;
	box-shadow: $hoffset $voffset $blur $spread $color;
}

// BOX SHADOW INNER
@mixin box-shadow-inner($hoffset, $voffset, $blur, $spread, $color) {
	-webkit-box-shadow: inset $hoffset $voffset $blur $spread $color;
	-moz-box-shadow: inset $hoffset $voffset $blur $spread $color;
	box-shadow: inset $hoffset $voffset $blur $spread $color;
}


@mixin blurBackground($blurPixels) {
	-webkit-backdrop-filter: blur($blurPixels);
	backdrop-filter: blur($blurPixels);
}

@mixin loadingFade() {
	-webkit-animation: fade 2s infinite;
	animation: fade 2s linear infinite;

	@-webkit-keyframes fade {
		0% {@include opacity(1);}
		45% {@include opacity(0);}
		55% {@include opacity(0);}
		100% {@include opacity(1);}
	}

	@keyframes fade {
		0% {@include opacity(1);}
		45% {@include opacity(0);}
		55% {@include opacity(0);}
		100% {@include opacity(1);}
	}
}

@mixin groupBackgroundColor() {
	.groupBackground1 {
		background-color: $group1;
	}
	.groupBackground2 {
		background-color: $group2;
	}
	.groupBackground3 {
		background-color: $group3;
	}
	.groupBackground4 {
		background-color: $group4;
	}
	.groupBackground5 {
		background-color: $group5;
	}
	.groupBackground6 {
		background-color: $group6;
	}
}

@mixin groupTextColor() {
	.groupColor1 {
		color: $group1;
	}
	.groupColor2 {
		color: $group2;
	}
	.groupColor3 {
		color: $group3;
	}
	.groupColor4 {
		color: $group4;
	}
	.groupColor5 {
		color: $group5;
	}
	.groupColor6 {
		color: $group6;
	}
}

@mixin bidBoxPositions() {
	&.position0 {
		left: 62em;
		bottom: 25em;
	}

	&.position1 {
		left: 55em;
		bottom: 10em;
	}

	&.position2 {
		left: 30em;
		bottom: 15em;
	}

	&.position3 {
		left: 6em;
		bottom: 25em;
	}

	&.position4 {
		left: 35em;
		bottom: 30em;
	}

	&.position5 {
		left: 10em;
		bottom: 7em;
	}
}

@mixin noHighlight() {
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Chrome, Edge, Opera and Firefox */
}

@mixin fadeIn($seconds: '0.5s', $timing: 'ease', $delay: '0s') {
	@include opacity(0);
	animation: fadeIn $seconds #{$timing} $delay forwards;
	-webkit-animation: fadeIn $seconds #{$timing} $delay forwards;
}

@mixin fadeOut($seconds: '0.5s', $timing: 'ease', $delay: '0s') {
	animation: fadeOut $seconds #{$timing} $delay forwards;
	-webkit-animation: fadeOut $seconds #{$timing} $delay forwards;
}

@mixin pulse($seconds: '0.5s') {
	@include opacity(0);
	animation: fadeInOut $seconds infinite;
	-webkit-animation: fadeInOut $seconds infinite;
}

@mixin bounce($seconds: '0.5s') {
	animation: bounce $seconds ease-in-out;
	-webkit-animation: bounce $seconds ease-in-out;
}

@mixin animateCorrect() {
	-webkit-animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
	-webkit-animation-fill-mode: forwards;
	animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
	animation-fill-mode: forwards;
}

@mixin animateWrong() {
	-webkit-animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
	-webkit-animation-fill-mode: forwards;
	animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
	animation-fill-mode: forwards;
}


@mixin pushable-button() {
	background-color: $uiGradientLightBlue;
	color: $text-light;

	border-radius: 0.5em;
	
	padding-left: 3em;
	padding-right: 3em;
	padding-top: 0.45em;
	padding-bottom: 0.45em;

	border-top: solid;
	border-top-color: $uiLightBlue;
	border-top-width: 0.2em;

	@include transition(0.1s, 'all');
	@include box-shadow(0em, 0.3em, 0, 0, $uiGradientDarkBlue);

	span {
		font-size: 1em;
	}

	&.cart {
		background-image: url('../assets/images/icons/cart1.svg');
		background-size: 2.5em 2em;
		background-position: left 1em top 50%;
		padding-left: 5em;
		padding-right: 5em;
	}

	&.van {
		background-image: url('../assets/images/icons/van1.svg');
		background-size: 2.5em 2em;
		background-position: left 1em top 50%;
		padding-left: 5em;
		padding-right: 5em;
	}

	&.darkBlue {
		background-color: $uiGradientDarkBlue;
		@include box-shadow(0em, 0.3em, 0, 0, $uiDarkBlue);
		border-top-color: $uiGradientLightBlue;

		&.cart {
			background-image: url('../assets/images/icons/cart2.svg');
			background-size: 2.5em 2em;
			background-position: left 1em top 50%;
			padding-left: 5em;
			padding-right: 5em;
		}

		&.van {
			background-image: url('../assets/images/icons/van2.svg');
			background-size: 2.5em 2em;
			background-position: left 1em top 50%;
			padding-left: 5em;
			padding-right: 5em;
		}

		span
		{
			color: $lightBlue;
		}
	}

	&:hover {
		// Translating y position and making bottom lined box shadow slimmer by same amount,
		// to simulate button extenting
		@include translate(0, -0.2em);

		@include box-shadow(0em, 0.5em, 0, 0, $uiGradientDarkBlue);
		&.darkBlue {
			@include box-shadow(0em, 0.5em, 0, 0, $uiDarkBlue);
		}
	}
	&:active {
		// Translating y position and making bottom lined box shadow slimmer by same amount,
		// to simulate button pressing
		@include translate(0, 0.2em);
		@include box-shadow(0em, 0.1em, 0, 0, $uiGradientDarkBlue);
		&.darkBlue {
			@include box-shadow(0em, 0.1em, 0, 0, $uiDarkBlue);
		}
	} 
}
@mixin correctAnswer-button() {
	background-color: $correctAnswer;
	color: $text-light;

	border-radius: 0.5em;
	
	padding-left: 3em;
	padding-right: 3em;
	padding-top: 0.45em;
	padding-bottom: 0.45em;

	border-top: solid;
	border-top-color: $correctAnswerBorder;
	border-top-width: 0.2em;

	@include transition(0.1s, 'all');
	@include box-shadow(0em, 0.3em, 0, 0, $correctAnswerShadow);

	
	@include no-select();
	pointer-events: none;
	span {
		font-size: 1em;
	}
}
@mixin wrongAnswer-button() {
	background-color: $wrongAnswer;
	color: $text-light;

	border-radius: 0.5em;
	
	padding-left: 3em;
	padding-right: 3em;
	padding-top: 0.45em;
	padding-bottom: 0.45em;

	border-top: solid;
	border-top-color: $wrongAnswerBorder;
	border-top-width: 0.2em;

	@include transition(0.1s, 'all');
	@include box-shadow(0em, 0.3em, 0, 0, $wrongAnswerShadow);

	cursor: not-allowed;
	span {
		font-size: 1em;
	}
}
@mixin completedAnswer-button() {
	background-color: $completedAnswer;
	color: $text-light;

	border-radius: 0.5em;
	
	padding-left: 3em;
	padding-right: 3em;
	padding-top: 0.45em;
	padding-bottom: 0.45em;

	border-top: solid;
	border-top-color: $completedAnswerBorder;
	border-top-width: 0.2em;

	@include transition(0.1s, 'all');
	@include box-shadow(0em, 0.3em, 0, 0, $completedAnswerShadow);

	cursor: not-allowed;
	span {
		font-size: 1em;
	}
}
@mixin shopitems() {
	// Machines
	&.machine-0,
	&.machine-1 {
		#logo-1,
		#logo-2,
		#logo-3,
		#logo-4,
		#logo-5,
		#logo-6 {
			display: none;
		}

		&.group-1 {
			#logo-1 {
				display: block;
			}
		}

		&.group-2 {
			#logo-2 {
				display: block;
			}
		}

		&.group-3 {
			#logo-3 {
				display: block;
			}
		}

		&.group-4 {
			#logo-4 {
				display: block;
			}
		}

		&.group-5 {
			#logo-5 {
				display: block;
			}
		}

		&.group-6 {
			#logo-6 {
				display: block;
			}
		}
	}
	&.machine-2 {
		background-image: url(../assets/images/shopItems/machines/machine-2.svg);
	}
	&.machine-3 {
		background-image: url(../assets/images/shopItems/machines/machine-3.svg);
	}
	&.machine-4 {
		background-image: url(../assets/images/shopItems/machines/machine-4.svg);
	}
	&.machine-5 {
		background-image: url(../assets/images/shopItems/machines/machine-5.svg);
	}
	&.machine-6 {
		background-image: url(../assets/images/shopItems/machines/machine-6.svg);
	}
	// Coworkers
	&.coworker-7 {
		background-image: url(../assets/images/shopItems/coworkers/coworker-7.svg);
	}
	&.coworker-8 {
		background-image: url(../assets/images/shopItems/coworkers/coworker-8.svg);
	}
	&.coworker-9 {
		background-image: url(../assets/images/shopItems/coworkers/coworker-9.svg);
	}
	// Educations
	&.education-10 {
		background-image: url(../assets/images/shopItems/educations/education-10.svg);
	}
	&.education-11 {
		background-image: url(../assets/images/shopItems/educations/education-11.svg);
	}
	&.education-12 {
		background-image: url(../assets/images/shopItems/educations/education-12.svg);
	}
	&.education-13 {
		background-image: url(../assets/images/shopItems/educations/education-13.svg);
	}
}

@mixin groupStatIcons() {
	&.quality
	{
		background-image: url(../assets/images/icons/icon-quality.svg);
	}
	&.workenvironment
	{
		background-image: url(../assets/images/icons/icon-workenvironment.svg);
	}
	&.sustainability
	{
		background-image: url(../assets/images/icons/icon-sustainability.svg);
	}
}

@mixin rotate3D($x, $y, $z, $speed) {
	-webkit-animation: rotate3D $speed linear infinite;
	animation: rotate3D $speed linear infinite;

	@-webkit-keyframes rotate3D {
		0% {-webkit-transform: rotate3d($x, $y, $z, 0deg);}
		50% {-webkit-transform: rotate3d($x, $y, $z, 180deg);}
		100% {-webkit-transform: rotate3d($x, $y, $z, 360deg);}
	}
	
	@keyframes rotate3D {
		0% {transform: rotate3d($x, $y, $z, 0deg);}
		50% {transform: rotate3d($x, $y, $z, 180deg);}
		100% {transform: rotate3d($x, $y, $z, 360deg);}
	}
}

@mixin fall($from, $to, $duration) {
	-webkit-animation: fall linear infinite;
	animation: fall linear infinite;

	@-webkit-keyframes fall {
		0% {-webkit-transform: translate3d(0, $from, 0);}
		100% {-webkit-transform: translate3d(0, $to, 0);}
	}

	@keyframes fall {
		0% {transform: translate3d(0, $from, 0);}
		100% {transform: translate3d(0, $to, 0);}
	}
}