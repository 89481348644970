/* Colors */
$lightBlue: #96C0E2;
$darkBlue: #0B2A53;
$blue: #0F4B98;
$offWhite: #F4F4F4;
$white: #ffffff;
$overlayBlue: #092954;
$gray: #B5BFCC;
$lightGray: #EBF1F5;
$lightGrayBlue: #D8E4EC;

$transparentDarkBlue: rgba(#00324A, 0.8);
$boxShadow: rgba(0, 0, 0, 0.3);

$gradient1: #BDE8F4;
$gradient2: #7CD4E8;

$radialGradient1: #BBCFDD;

$workshopGradient1: #60A2AB;
$workshopGradient2: #72B0BA;
$workshopGradient3: #83BDC7;
$workshopGradient4: #8CC5CF;
$workshopGradient5: #90C8D2;

$uiGradientLightBlue: #2C71CB;
$uiGradientDarkBlue: #07467B;
$uiDarkBlue: #092954;
$uiLightBlue: #61A2F5;
$lightBlueHighlight: #5496EA;
$darkBlueAssignment: #002845;

$floor: #749AA8;

$topBorderLightBlue: #2580EB; 

$boxBottomGray: #B0C9D9;

$deleteButtonRed: #80302D;

$text-light: #ffffff;
$text-blue: #0E4B99;
$text-dark: #022845;

$correctAnswer: #079162;
$correctAnswerBorder: #1BBF81;
$correctAnswerShadow: #01654D;

$wrongAnswer: #BF2023;
$wrongAnswerBorder: #E65C5E;
$wrongAnswerShadow: #98070A;

$completedAnswer: rgba($uiGradientLightBlue, 0.6);
$completedAnswerBorder: rgba($uiLightBlue, 0.6);
$completedAnswerShadow: rgba($blue, 0.6);

$gold: #DDA926;

// Group Colors //
$group1: #EB1CB5;
$group2: #4DB4FC;
$group3: #60CB4D;
$group4: #F85454;
$group5: #E3A626;
$group6: #7D64A8;