@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GroupBox:hover
{
	&.isButton {
		@include transition(0.1s, 'height');
		height: 17em;
		
		&.pressed {
			@include transition(0.01s, 'height');
			height: 16.5em;
		}
	}
}

.GroupBox
{
	@include groupBackgroundColor();
	@include transition(0.5s, 'opacity');
	width: 14em;
	height: 16.5em;

	&.isButton {
		cursor: pointer;
	}
	
	&.bounce {
		@include bounce(0.75s);
	}

	&.transparent {
		opacity: 0.6;
	}

	.GroupBox-groupWrapper
	{
		@include flex('center', 'center', 'column');
		width: 100%;
		height: 100%;
		
		.GroupBox-groupBox:hover
		{
			&.isButton {
				box-shadow: 0em 0em 0em 0.3em $darkBlue;
			}
		}

		.GroupBox-groupBox
		{
			@include transition(0.25s, 'box-shadow');
			@include box-shadow(0em, 0.3em, 0, 0, $uiGradientDarkBlue);
			position: relative;
			width: 100%;
			height: 100%;
			border-radius: 0.5em;

			.GroupBox-groupBoxOverlayWrapper {
				pointer-events: none;
				position: absolute;
				width: 100%;
				height: 100%;

				.GroupBox-groupBoxOverlay
				{
					border-radius: 0.5em;
					// Rounding further to avoid white pixels due to rounding errors
					border-top-right-radius: 1em;
					width: 100%;
					height: 16em;
					background-color: $white;

					.GroupBox-groupBoxCornerWrapper
					{
						top: 0em;
						right: 0em;
						position: absolute;
						clip-path: polygon(100% 100%, 100% 0, 0 0);

						.GroupBox-groupBoxCorner
						{
							box-shadow: inset 0.15em 0.15em 0.3em $boxShadow;
							position: relative;
							height: 3em;
							width: 3em;
							border-top-right-radius: 0.5em;
						}
					}

					.GroupBox-groupName
					{
						padding: 0.5em;
						margin-bottom: 1em;
						span
						{
							font-size: 0.9em;
							font-weight: bold;
							text-transform: uppercase;
						}
					}

					.GroupBox-groupBoxOverlayContent
					{
						@include flex('', 'center', 'column');

						.GroupBox-groupCapacityBox 
						{
							@include flex('center', 'center');
							width: 3em;
							height: 3em;
							box-shadow: inset 0.15em 0.15em 0.3em $boxShadow;
							border-radius: 0.3em;

							span
							{
								color: $text-light;
								font-size: 1.8em;
								font-weight: bold;
							}
						}

						.GroupBox-groupCapacityTitle
						{
							margin-bottom: 0.5em;
							margin-top: 0.25em;

							span 
							{
								font-weight: bold;
							}
						}

						.GroupBox-groupDetails
						{
							@include flex('', 'center');
							position: relative;
							width: 85%;
							height: 2em;
							border-radius: 1em;
							margin-bottom: 0.5em;
							background-color: $lightGrayBlue;

							span {
								width: 20%;
								font-size: 0.8em;
								font-weight: 500;
							}

							.GroupBox-groupDetailIcon
							{
								width: 40%;
								height: 100%;
								width: 2em;
								background-size: contain;
								background-position: top 0em left 0em;
								margin-right: 1em;

								@include groupStatIcons();
							}

							.GroupGox-groupDetailValue {
								text-align: right;
								width: 40%;
								margin-left: 1em;
								margin-right: 1em;
								font-weight: bold;
							}
						}
					}
				}
			}

			.GroupBox-boxBottom
			{
				border-radius: 0.5em;
				// Rounding further to avoit white pixels due to rounding errors
				border-top-right-radius: 1em;
				width: 100%;
				height: 100%;
				background-color: $boxBottomGray;
				box-shadow: 0.2em 0.2em 0.3em $boxShadow;
			}
		}
	}
}