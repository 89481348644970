@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Background
{
	z-index: 1;
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 100%;

	.Background-city {
		position: absolute;
		bottom: 0;
		height: auto;
		max-height: 100%;
		width: 100%;

		#background-city {
			transform-origin: bottom;
		}

		#mask-foreground,
		#mask-buildings,
		#background {
			transform-origin: center;
			transform: scaleX(3);
		}

		#cloud-1
		{
			@include floatRight(85s, -50s);
		}
		#cloud-2
		{
			@include floatRight(95s, 0s);
		}
		#cloud-3
		{
			@include floatRight(110s, -40s);
		}

		#truck-1
		{
			@include floatRight(10s, 0s);
		}
		#truck-2
		{
			@include floatLeft(10s, 0s);
		}

		#logo-1,
		#logo-2,
		#logo-3,
		#logo-4,
		#logo-5,
		#logo-6 {
			display: none;
		}

		&.group-1 {
			#logo-1 {
				display: block;
			}
		}

		&.group-2 {
			#logo-2 {
				display: block;
			}
		}

		&.group-3 {
			#logo-3 {
				display: block;
			}
		}

		&.group-4 {
			#logo-4 {
				display: block;
			}
		}

		&.group-5 {
			#logo-5 {
				display: block;
			}
		}

		&.group-6 {
			#logo-6 {
				display: block;
			}
		}

		#car-1
		{
			@include floatLeft(12s, -5s);
		}
		#car-2
		{
			@include floatLeft(11s, -8s);
		}
		#car-3
		{
			@include floatLeft(10s, -7s);
		}
	}

	.Background-workshopGradient {
		position: relative;
		height: 50em;
		width: 100%;
		background-image: linear-gradient(
			to top, 
			$workshopGradient1 35%,
			$workshopGradient2 50%,
			$workshopGradient3 65%,
			$workshopGradient4 85%,
			$workshopGradient5
		);
	}

	.Background-overlay {
		top: 0;
		z-index: 3;
		position: absolute;
		pointer-events: none;
		background-image: url('../../../assets/images/background-overlay.svg');
		background-size: 100% 100%;
		width: 100%;
		height: 100%;
	}
	.Background-workshop {
		z-index: 2;
		position: absolute;
		width: 100%;
		max-height: 100%;
		height: auto;
		top: 0;

		#exoskeleton,
		#cuttingRobot,
		#portableTableSaw,
		#batteryTools,
		#handCircularSaw,
		#sustainableConstruction,
		#safetyOnTheConstructionSite,
		#climateNeutralConstruction,
		#qualityAssuranceCourse,
		#worker,
		#svend,
		#student {
			display: none;
		}
		#window {
			#Truck_2_udenfor,
			#Truck_3_udenfor {
				display: none;
			}

			#Truck_1_udenfor,
			#Truck_2_udenfor,
			#Truck_3_udenfor {
				#logo-1-3,
				#logo-2-3,
				#logo-3-3,
				#logo-4-3,
				#logo-5-3,
				#logo-6-3,
				#logo-1-2,
				#logo-2-2,
				#logo-3-2,
				#logo-4-2,
				#logo-5-2,
				#logo-6-2,
				#logo-1,
				#logo-2,
				#logo-3,
				#logo-4,
				#logo-5,
				#logo-6 {
					display: none;
				}
			}
		}

		&.group-1 {
			#Truck_1_udenfor,
			#Truck_2_udenfor,
			#Truck_3_udenfor {
				#logo-1-3,
				#logo-1-2,
				#logo-1 {
					display: block !important;
				}
			}
		}

		&.group-2 {
			#Truck_1_udenfor,
			#Truck_2_udenfor,
			#Truck_3_udenfor {
				#logo-2-3,
				#logo-2-2,
				#logo-2 {
					display: block !important;
				}
			}
		}

		&.group-3 {
			#Truck_1_udenfor,
			#Truck_2_udenfor,
			#Truck_3_udenfor {
				#logo-3-3,
				#logo-3-2,
				#logo-3 {
					display: block !important;
				}
			}
		}

		&.group-4 {
			#Truck_1_udenfor,
			#Truck_2_udenfor,
			#Truck_3_udenfor {
				#logo-4-3,
				#logo-4-2,
				#logo-4 {
					display: block !important;
				}
			}
		}

		&.group-5 {
			#Truck_1_udenfor,
			#Truck_2_udenfor,
			#Truck_3_udenfor {
				#logo-5-3,
				#logo-5-2,
				#logo-5 {
					display: block !important;
				}
			}
		}

		&.group-6 {
			#Truck_1_udenfor,
			#Truck_2_udenfor,
			#Truck_3_udenfor {
				#logo-6-3,
				#logo-6-2,
				#logo-6 {
					display: block !important;
				}
			}
		}

		&.exoskeleton #exoskeleton,
		&.cuttingRobot #cuttingRobot,
		&.portableTableSaw #portableTableSaw,
		&.batteryTools #batteryTools,
		&.handCircularSaw #handCircularSaw,
		&.sustainableConstruction #sustainableConstruction,
		&.safetyOnTheConstructionSite #safetyOnTheConstructionSite,
		&.climateNeutralConstruction #climateNeutralConstruction,
		&.qualityAssuranceCourse #qualityAssuranceCourse,
		&.svend #svend,
		&.student #student,
		&.worker #worker {
			@include bounce(1s);
			display: block;
		}

		&.betterCar {
			#window {
				#Truck_1_udenfor,
				#Truck_3_udenfor {
					display: none;
				}
				#Truck_2_udenfor {
					#Truck_2_wrapper {
						@include bounce(1s);
					}
					display: block;
				}
			}
		}
		&.extraCar {
			#window {
				#Truck_1_udenfor{
					display: none;
				}
				#Truck_3_udenfor {
					#Truck_3_wrapper {
						@include bounce(1s);
					}
					display: block;
				}
			}
		}

		
	}

	.Background-floorColor {
		position: relative;
		background-color: $floor;
		width: 100%;
		height: 100%;
	}

	.Background-backgroundBackdrop {
		z-index: 1;
		position: absolute;
		width: 100%;
		max-height: 100%;
		height: auto;
		top: 0;

		#background-workshopBackdrop {
			transform: scaleX(10) translateX(-25%);
		}
	}
}