@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PlayButton {
	position: absolute;
	cursor: pointer;
	z-index: 3;
	right: 1.45em;
	bottom: 2.4em;
	width: 2.5em;
	height: 2.5em;
	background-image: url(../../../assets/images/icons/icon-play.svg);
	background-size: contain;

	&.isPlaying {
		background-image: url(../../../assets/images/icons/icon-pause.svg);
	}

	&:hover {
		@include scale(1.2);
	}
}