@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Admin {
	@include flex('center', 'center', 'column');
	position: relative;
	width: 100%;
	height: 100%;
	color: $text-dark;
	background-size: 100% 100%;

	.Admin-statisticsWrapper {
		.Admin-statistics {
			
		}
	}
	.Admin-logoutBtn {
		position: absolute;
		bottom: 1em;
		left: 1em;
	}
}