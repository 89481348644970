@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.BidOverview 
{
	z-index: 3;
	height: 100%;
	@include flex('space-between','center','column');

	.BidOverview-headerWrapper
	{
		@include flex('space-between','flex-start','row');
		width: 100%;

		.BidOverview-gameCode
		{
			margin: 1em;
			position: relative;
		}

		.BidOverview-progressbar {
			margin: 1em;
			position: relative;
		}

		.BidOverview-sidebar
		{
			position: relative;
		}
	}

	.BidOverview-bids
	{
		height: 100%;
		.BidOverview-bidBox
		{
			position: absolute;

			@include bidBoxPositions();
		}
	}

	.BidOverview-button
	{
		margin-bottom: 5em;
	}
}