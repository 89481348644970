@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.AdminLogin {
	@include flex('center', 'center');
	height: 100%;
	width: 100%;

	.AdminLogin-logo {
		position: absolute;
		top: 2em;
		left: calc(50% - 0.5 * 15.33em);
		width: 15.33em;
		height: 6.78em;
	}

	.AdminLogin-boxWrapper {
		width: 29em;
		height: 22.5em;
		@include box-shadow(0em, 0.65em, 0.2em, 0.05em, rgba(#0B2A53, 0.4));
		border-radius: 0.5em;
		.AdminLogin-box {
			height: 100%;
			position: relative;
			color: $text-dark;
			background-color: $white;
			border-radius: 0.5em;
			box-shadow: 0em 0.45em $boxBottomGray;
			@include flex('center', 'center', 'column');

			.AdminLogin-form {
				@include flex('center', 'center', 'column');
				
				.AdminLogin-formHeader {
					margin-top: 3em;
					p {
						color: $text-dark;
						font-size: 1.5em;
						font-weight: 900;
						text-align: center;
						margin-top: 0;
					}
				}
				input {
					display: block;
					font-size: 1.11em;
					width: 15em;
					height: 2.3em;				
					border-radius: 0.5em;
					color: $text-dark;
					background-color: $lightGrayBlue;
					margin: 0.75em auto;		
					padding: 0.5em;
					@include placeholderColor($text-dark);
				}
				// Fixing chrome setting the background color to white on autofill
				input:-webkit-autofill {
					// For some reason, chrome does not allow background-color on autofill
					// This is solved here by settings a large inset box-shadow with the desired background color
					box-shadow: 0 0 0 10em $lightGrayBlue inset !important;
					-webkit-text-fill-color: $text-dark;
				}

				.AdminLogin-button {
					height: 2.5em;
				}
		
				.AdminLogin-errorMessage {
					pointer-events: none;
					height: 1.5em;
					margin: 0.6em;
					margin-bottom: 1.5em;
					width: calc(100% - 1.2em);
					font-size: 0.85em;
					color: red;
					text-align: center;
				}
			}
		}
	}
}