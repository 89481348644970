@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GroupData
{
	@include groupBackgroundColor();
	@include transition(0.5s, 'opacity');
	width: 14em;
	height: 4.5em;

	.GroupData-groupWrapper
	{
		@include flex('center', 'center', 'column');
		width: 100%;
		height: 100%;

		.GroupData-groupBox
		{
			@include transition(0.25s, 'box-shadow');
			@include box-shadow(0em, 0.3em, 0, 0, $uiGradientDarkBlue);
			position: relative;
			width: 100%;
			height: 100%;
			border-radius: 0.5em;

			.GroupData-groupBoxOverlayWrapper {
				pointer-events: none;
				position: absolute;
				width: 100%;
				height: 100%;

				.GroupData-groupBoxOverlay
				{
					padding: 0.5em;
					border-radius: 0.5em;
					// Rounding further to avoid white pixels due to rounding errors
					border-top-right-radius: 1em;
					width: 100%;
					height: 4em;
					background-color: $white;

					.GroupData-groupBoxCornerWrapper
					{
						top: 0em;
						right: 0em;
						position: absolute;
						clip-path: polygon(100% 100%, 100% 0, 0 0);

						.GroupData-groupBoxCorner
						{
							box-shadow: inset 0.2em 0.2em 0.3em $boxShadow;
							position: relative;
							height: 2.25em;
							width: 2.25em;
							border-top-right-radius: 0.5em;
						}
					}

					.GroupData-groupName
					{
						padding-bottom: 0.25em;
						span
						{
							font-size: 0.9em;
							font-weight: bold;
							text-transform: uppercase;
						}
					}

					.GroupData-groupBoxOverlayContent
					{
						@include flex('', '', 'row');

						.GroupData-groupCapacityBox 
						{
							@include flex('center', 'center');
							width: 1.5em;
							height: 1.5em;
							box-shadow: inset 0.15em 0.15em 0.3em $boxShadow;
							border-radius: 0.3em;

							span
							{
								color: $text-light;
								font-size: 1em;
								font-weight: bold;
							}
						}

						.GroupData-groupDetails
						{
							@include flex('', 'center', 'row');
							position: relative;
							height: 1.5em;
							border-radius: 1em;
							background-color: $lightGrayBlue;
							margin-left: 0.5em;

							span {
								width: 20%;
								font-size: 0.8em;
								font-weight: 500;
							}

							.GroupData-groupDetailIcon
							{
								width: 1.75em;
								height: 1.5em;
								background-size: contain;
							
								@include groupStatIcons();
							}

							.GroupGox-groupDetailValue {
								text-align: right;
								width: 40%;
								margin-right: 0.5em;
								font-weight: bold;
							}
						}
					}
				}
			}

			.GroupData-boxBottom
			{
				border-radius: 0.5em;
				// Rounding further to avoid white pixels due to rounding errors
				border-top-right-radius: 1em;
				width: 100%;
				height: 100%;
				background-color: $boxBottomGray;
				box-shadow: 0.2em 0.2em 0.3em $boxShadow;
			}
		}
	}
}