@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Frontpage {
	@include flex('space-between', 'center', 'column');
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;

	.Frontpage-titleWrapper {
		@include flex('flex-start', 'center', 'column');
		z-index: 2;
		height: 100%;
		width: 100%;
		
		.Frontpage-title {
			margin-top: 3em;
			position: relative;
			width: 100%;
			height: 21.7em;

			background-size: contain;
			background-image: url(../../assets/images/woodlabTitle.svg);
		}

		.Frontpage-startButton {
			z-index: 2;
			margin-top: 4.3em;
		}
		.Frontpage-guideButton {
			z-index: 2;
			margin-top: 1em;
		}
	}

	.Frontpage-logos {
		@include flex('space-between', 'center');
		z-index: 2;
		width: 100%;
		height: 7.2em;
		background-color: rgba($white, 0.5);

		.Frontpage-logoUnord {
			cursor: pointer;
			margin-left: 4em;
			width: 9.4em;
			height: 1.55em;
			background-image: url(../../assets/images/logos/unord_logo.svg);
			background-size: contain;
		}
		.Frontpage-logoCGL {
			cursor: pointer;
			height: 2em;
			width: 10.2em;
			background-image: url(../../assets/images/logos/cphgamelab_Logo.svg);
			background-size: contain;
		}
		.Frontpage-fogsFondWrapper {
			cursor: pointer;
			margin-right: 4em;
			width: 12em;
			line-height: 0.8em;
			span {
				color: $overlayBlue;
				font-weight: bold;
				font-size: 0.8em;
			}
			.Frontpage-fogsFond {
				margin-top: 0.25em;
				width: 9.4em;
				height: 2.45em;
				background-image: url(../../assets/images/logos/fogs-fond-logo.svg);
				background-size: contain;
			}
		}
	}
}