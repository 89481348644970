@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.MultipleChoice {
	position: relative;
	height: 100%;
	padding-top: 2em;
	padding-bottom: 1em;
	@include hide-scrollbar();
	overflow: auto;
		
	.MultipleChoice-intro {
		position: relative;
	}

	.MultipleChoice-options {
		width: 100%;
		.MultipleChoice-optionsWrap {
			@include flex('','','column');
			max-height: 10em;
			height: auto;
			flex-wrap: wrap;
			position: relative;
			width: 100%;

			&.singleLine {
				height: 25em;
				max-height: 25em;
				flex-wrap: nowrap;
			}

			&.tall {
				height: 33em;
				max-height: 33em;
			}

			.MultipleChoice-option {
				position: relative;
				font-weight: bold;
				text-align: center;
				margin-bottom: 1em;
				margin-right: 0.5em;
				margin-left: 0.5em;
				cursor: pointer;
				@include flex('center', 'center');
				@include no-select();
				&.animateCorrect {@include animateCorrect();}
				&.animateWrong {@include animateWrong();}
				&.disabled {cursor: not-allowed;}
			}
		}	
	}

	&:not(.images) {
		.MultipleChoice-options .MultipleChoice-optionsWrap {
			.MultipleChoice-option {
				&.notSelected
				{
					@include pushable-button();
				}
				&.selected,
				&.correct,
				&.animateCorrect
				{
					@include correctAnswer-button();
				}
				&.wrong,
				&.animateWrong
				{
					@include wrongAnswer-button();
				}
				&.completed {
					@include completedAnswer-button();
					cursor: not-allowed;
				}
			}
		}
	}

	&.images {
		.MultipleChoice-options {
			padding: 0;
			.MultipleChoice-optionsWrap {
				.MultipleChoice-option { 
					width: 10.89em;
					height: 12.17em;
					background: none;
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
					border: none;
					margin: 0;
					padding: 0;
					border-radius: 0;
					@include box-shadow(0, 0, 0, 0, transparent);
					span {
						display: none;
						&:nth-child(2) {
							position: absolute;
							bottom: 0.25em;
							left: 0.25em;
							width: 1.5em;
							height: 1.5em;
						}
					}
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {
						border: none;	
						@include box-shadow(0, 0, 0, 0, transparent);
						span:nth-child(2) {display: block;}
					}
					&.correct,
					&.animateCorrect {
						span:nth-child(2) {
							background-image: url('../../../../assets/images/icons/icon-checkmark2.svg');
						}
					}
					&.wrong,
					&.animateWrong {
						span:nth-child(2) {
							background-image: url('../../../../assets/images/icons/icon-error.svg');
						}
					}
				}
			}
		}
	}

	&.toolbox {
		.MultipleChoice-options {
			position: relative;
			padding-top: 0.5em;
			width: 100%;

			.MultipleChoice-optionsWrap {
				@include flex('center', 'center', 'row');
				flex-wrap: wrap;
				max-height: 100%;
				width: 100%;

				.MultipleChoice-option {
					position: relative;
					margin: 0.25em;

					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 2em;
						height: 2em;
						background-size: contain;
					}

					&.option-1 {
						background-image: url('../../../../assets/images/assignments/set-a-1/multiple-choice/toolbox/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../assets/images/assignments/set-a-1/multiple-choice/toolbox/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../assets/images/assignments/set-a-1/multiple-choice/toolbox/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../assets/images/assignments/set-a-1/multiple-choice/toolbox/option-4.svg');
					}
					&.option-5 {
						background-image: url('../../../../assets/images/assignments/set-a-1/multiple-choice/toolbox/option-5.svg');
					}
					&.option-6 {
						background-image: url('../../../../assets/images/assignments/set-a-1/multiple-choice/toolbox/option-6.svg');
					}
					&.option-7 {
						background-image: url('../../../../assets/images/assignments/set-a-1/multiple-choice/toolbox/option-7.svg');
					}
				}
			}
		}
	}
	
	&.triangles {
		.MultipleChoice-options {
			position: relative;
			padding-top: 0.5em;
			width: 100%;

			.MultipleChoice-optionsWrap {
				@include flex('center', 'center', 'row');
				flex-wrap: wrap;
				max-height: 100%;
				width: 100%;

				.MultipleChoice-option {
					position: relative;
					margin: 0.25em;

					span:nth-child(2) {
						bottom: 0.5em;
						right: 0.75em;
						left: auto;
						width: 2em;
						height: 2em;
						background-size: contain;
					}

					&.option-1 {
						background-image: url('../../../../assets/images/assignments/assignment-41/option-1.svg');
					}
					&.option-2 {
						background-image: url('../../../../assets/images/assignments/assignment-41/option-2.svg');
					}
					&.option-3 {
						background-image: url('../../../../assets/images/assignments/assignment-41/option-3.svg');
					}
					&.option-4 {
						background-image: url('../../../../assets/images/assignments/assignment-41/option-4.svg');
					}
					&.option-5 {
						background-image: url('../../../../assets/images/assignments/assignment-41/option-5.svg');
					}
					&.option-6 {
						background-image: url('../../../../assets/images/assignments/assignment-41/option-6.svg');
					}
					&.option-7 {
						background-image: url('../../../../assets/images/assignments/assignment-41/option-7.svg');
					}
					&.option-8 {
						background-image: url('../../../../assets/images/assignments/assignment-41/option-8.svg');
					}
					&.option-9 {
						background-image: url('../../../../assets/images/assignments/assignment-41/option-9.svg');
					}
				}
			}
		}
	}
}