@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ConfettiCannon {
	position: absolute;
	z-index: 2;
	height: 100%;
	width: 100%;

    .ConfettiCannon-confettiWrapper {
        pointer-events: none;
        .ConfettiCannon-fallingConfetti {
            position: absolute;
            top: -1em;
            @include fall(-1em, 100vh, 5);
            .ConfettiCannon-rotatingConfetti {
                width: 0.3em;
                height: 1em;
                @include rotate3D(0, 1, 0.3, 0.5s);
            }
        }
    }
}