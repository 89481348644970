@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.BidResult 
{
	z-index: 3;
	height: 100%;
	@include flex('space-between','center','column');

	.BidResult-headerWrapper
	{
		@include flex('space-between','flex-start','row');
		width: 100%;

		.BidResult-logo
		{
			padding: 1em;
		}

		.BidResult-sidebar
		{
			@include flex('', '', 'column');
			z-index: 2;
			position: absolute;
	
			right: 0em;
			top: 1em;
			max-height: 95%;
			overflow-y: scroll;
			@include hide-scrollbar();
	
			padding: 1em;
			width: 16em;
	
			color: $uiDarkBlue;
			border-left: solid;
			border-left-width: 0.1em;
			border-bottom: solid;
			border-bottom-width: 0.3em;
	
			border-top: solid;
			border-top-color: $uiLightBlue;
			border-top-width: 0.2em;
	
			border-top-left-radius: 0.5em;
			border-bottom-left-radius: 0.5em;
	
			background-image: linear-gradient(
				to bottom,
				$uiGradientLightBlue 10%,
				$uiGradientDarkBlue 40%
			);
	
			.BidResult-sidebarTitle
			{
				margin-bottom: 0.5em;
				span
				{
					color: $text-light;
					font-weight: 900;
					font-size: 1.2em;
					text-transform: uppercase;
				}
			}
	
			.BidResult-currentGroup
			{
				margin-bottom: 0.25em; 
			}
	
			.BidResult-groups
			{
				position: relative;
	
				.BidResult-groupInfo
				{
					margin-top: 0.5em;
				}
			}
		}
	}

	.BidResult-bids
	{
		@include flex('', '', 'row');

		.BidResult-bidBox
		{
			position: absolute;

			@include bidBoxPositions();
		}
	}
}