@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.InputAnswer {
	@include flex('space-between', '', 'column');
	position: relative;
	height: 100%;
	padding-top: 2em;
	overflow: hidden;
		
	.InputAnswer-intro {
		position: relative;
		z-index: 1;
		height: 100%;
	}

	.InputAnswer-footer {
		z-index: 2;
		@include flex('center', 'flex-end', 'column');
		.InputAnswer-inputWrapper {

			.InputAnswer-form 
			{
				@include flex('', 'flex-end', 'column');
				.InputAnswer-input
				{
					@include flex('', 'flex-end', 'row');
					margin-bottom: 0.5em;
					
					span {
						font-weight: bold;
						text-transform: uppercase;
						font-size: 1.2em;
					}

					input 
					{
						display: block;
						width: 10em;
						height: 2.5em;
						margin-right: 0.5em;
						
						border: solid;
						border-radius: 0.35em;
						border-color: $uiGradientLightBlue;
						
						background-color: $lightGrayBlue;
						margin-left: 0.75em;
						
						color: $darkBlue;

						&::placeholder {color: $darkBlue;}
					}

					&.disabled
					{
						input
						{
							border-color: $gray;
							color: $gray;
						}
					}
				}
				.InputAnswer-answerButtonWrapper {
					@include flex('flex-start', 'flex-end', 'row');
					width: 100%;
					.InputAnswer-answerButton {
						width: 10em;
						margin-left: 4.25em;
					}
				}
			}
		}
	}
}