@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Timer {
	@include flex('','center','row');

	.Timer-box
	{
		padding: 2em;
		background-color: $uiGradientDarkBlue;

		border-radius: 0.5em;

		border-top: solid;
		border-top-color: $topBorderLightBlue;
		border-top-width: 0.25em;

		@include box-shadow(0em, 0.5em, 0, 0, $overlayBlue);
		margin-left: 0.4em;
		margin-right: 0.4em;

		span
		{
			color: $text-light;
			font-size: 5em;
			font-weight: bold;
		}
	}
	.Timer-colon
	{
		background-image: url('../../../assets/images/colon.svg');
		width: 2em;
		height: 5em;
		padding-right: 0.25em;
		padding-left: 0.25em;
		background-size: contain;
	}
}