@import '../../../../styles/variables';
@import '../../../../styles/fonts';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.OrderDndPreview {
	.OrderDndPreview-item {
		position: relative;
		width: 15.5em;
		left: 25.5em;
		top: 0.75em;
		min-height: 2.25em;
		line-height: 1.25em;
		border-radius: 0.55em;
		font-weight: bold;
		text-align: center;
		padding: 0.75em 1.25em 0.5em 1.25em;
		cursor: pointer;
		transform-origin: left 0.5em center;
		@include flex('center', 'center');
		@include no-select();
		@include rotate(-7deg);
		@include pushable-button();
		span {
			font-size: 0.85em;
		}		

		&::after {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			width: 2em;
			height: 100%;
			background-image: url('../../../../assets/images/icons/icon-arrow-cross.svg');
			background-position: top 0.5em left 0.25em;
			background-size: auto 1.25em;
			background-repeat: no-repeat;
		}
	}
}