@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.CornerLogo
{
	z-index: 2;
	@include flex('flex-start', 'center');
	position: relative;

	width: 10em;
	height: 5em;

	background-image: url(../../../assets/images/woodlabLogo.svg);
	background-size: contain;
	background-position: left calc(50%) top 0em;
}